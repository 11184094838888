import {Grid, CircularProgress, styled as materialStyled, TableContainer,SvgIcon, Table, Link, Paper, TableHead, TableRow, TableCell, TableBody, Typography, Stack, Box} from '@mui/material'
import { useIntl } from "react-intl"
import {useState, useEffect} from "react"
import { getAllNodes} from '../../../../API/index'
import {US,JP, SG, AU, CA, KR, IN, IE, SE, GB, FR} from 'country-flag-icons/react/3x2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { loadCSS } from 'fg-loadcss'
import Chart from 'react-google-charts'
import WorldMap from "react-svg-worldmap";
import { useTheme } from 'styled-components'
import { ReactComponent as IconArrow } from "../../../../assets/icons/arrow-right 1.svg";

ChartJS.register(ArcElement, Tooltip, Legend)
const ItemStyle = materialStyled(Paper)(() => ({
    textAlign: 'center',
    borderRadius: '1rem',
	border: '1px solid #E4E2E4',
    padding: '0.5rem',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	backgroundColor:'transparent',
	boxShadow:'none'
}))

const ItemStylePie = materialStyled(Paper)(() => ({
    textAlign: 'center',
    borderRadius: '1rem',
	border: '1px solid #E4E2E4',
    padding: '0.5rem',
	minHeight: '324px',
	width: '100%',
	backgroundColor:'transparent',
	boxShadow:"none",
}))

export interface nodeType {
	ip_addr: string
	minerAddr: string
	running: boolean
	wallet_addr: string
	balance: string
	country: string
	type: 'seed'|'super'
}
interface pieDatasets {
	label: string
	data: number[]
	backgroundColor: string[]
	borderColor: string[]
	borderWidth: number
}
interface pieData {
	labels: string[]
	datasets: pieDatasets[]
}

const countryGetIcon = (country: string) => {
	switch (country) {
		case 'US': {
			return <SvgIcon component={US} inheritViewBox/>
		}
		case 'AU': {
			return <SvgIcon component={AU} inheritViewBox/>
		}
		case 'CA': {
			return <SvgIcon component={CA} inheritViewBox/>
		}
		case 'KR': {
			return <SvgIcon component={KR} inheritViewBox/>
		}
		case 'IN': {
			return <SvgIcon component={IN} inheritViewBox/>
		}
		case 'IE': {
			return <SvgIcon component={IE} inheritViewBox/>
		}
		case 'SE': {
			return <SvgIcon component={SE} inheritViewBox/>
		}
		case 'SG': {
			return <SvgIcon component={SG} inheritViewBox/>
		}
		case 'JP': {
			return <SvgIcon component={JP} inheritViewBox/>
		}
		case 'GB': {
			return <SvgIcon component={GB} inheritViewBox/>
		}
		case 'FR': {
			return <SvgIcon component={FR} inheritViewBox/>
		}
	}
		
	
}

const PieChart = (minted: number) => {
	const theme = useTheme()
	const totalCNTP = 100000000
	const balance = totalCNTP - minted
	const intl = useIntl()
	const data2: pieData = {
		labels: [
			intl.formatMessage({id: 'platform.conet.explorer.CNTP.balance'}),
			intl.formatMessage({id: 'platform.conet.explorer.CNTP.mint'}),
		],
		datasets: [{
			label: '',
			data: [balance, minted],
			backgroundColor: [
				'#6e7b63',
				'#a1b095',
			],
			//@ts-ignore
			hoverOffset: 0,
			borderWidth: 0,
			pieHole: 0.7,
		}]
	}
	
    const colors = [theme.ui.colors.pieChart.primary,theme.ui.colors.pieChart.secondary]
	const options = {
		pieHole: 0.6,
		is3D: false,
		colors: colors,
		// slices: {
		// 	0: {color: theme.ui.colors.primary},
		// //   0: { color: '#79F8FF' },
		// //   1: { color: '#577DFF' }
		//   	1: { color: theme.ui.colors.secondary }
		// },
		backgroundColor: 'transparent',
		// legend:{position: 'right', textStyle: {color: '#000', fontSize: 14}},
        legend:'none',
		datalabels: false,
		pieSliceBorderColor: 'transparent',
		pieSliceText: 'none',
		chartArea: { left: 0, top: '5%', width: '90%', height: '90%' },
		// pointShape: 'square'
	  };
	const data = [
		["balance", "mint"],
		[intl.formatMessage({id: 'platform.conet.explorer.CNTP.balance'}), balance],
		[intl.formatMessage({id: 'platform.conet.explorer.CNTP.mint'}), minted],
	  ];
	  
	return (
		<Box width={'100%'}>
			{
				minted > 0 &&
				<Stack position={'relative'} direction={{lg: 'row', xs: 'column'}} sx={{width: '100%', gap: '16px', alignItems: 'center'}}>
					<Stack>
						<Chart
							chartType="PieChart"
							data={data}
							options={options}
							width="100%"
							height="100%"
							legendToggle
						/>
					</Stack>
                    <Box position={{lg:'absolute'}} right={{xs:'0px', lg:'50px'}}>
                        {data.slice(1).map((data, index) =>(
                            <Stack direction="row" sx={{alignItems: 'center', gap: 2}}>
                                <Box width={15} height={15} style={{backgroundColor: colors[index]}}/>
                                <Box fontWeight={'semibold'}>{data[0]}</Box>
                            </Stack>
                        ))}
                    </Box>
				</Stack>
			}
			{/* {
				minted > 0 &&
				<Doughnut data={data2} />
			} */}
		</Box>
		
	)
}

const Overview = () => {
	const intl = useIntl()
	const [lastBlock, setLastBlock] = useState(0)
	const [nodes, setNodes] = useState(0)
	const [runningNodes, setRunningNodes] = useState(0)
	const [walletCounting, setWalletCounting] = useState(0)
	const [CNTPBalance, setCNTPBalance] = useState('')
	const [CNTPMint, setCNTPMint] = useState(0)
	const [nodesDetail, setNodesDetail] = useState<nodeType[]>([])
	const [nodesDetailPage, setNodesDetailPage] = useState<nodeType[]>([])
	const theme = useTheme()
	
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1)

	const setDates = (data: any) => {
		const CNTPbalance = parseFloat(data.masterBalance.CNTPMasterBalance)+parseFloat(data.masterBalance.CNTPReferralBalance)
		const minted = (100000000-CNTPbalance).toFixed(0)
		setCNTPMint(parseFloat(minted))
		const bala = ((CNTPbalance/100000000)*100).toFixed(2)
		setCNTPBalance(bala+'%')
		const nodes: nodeType[] = data.node
		
		if (nodes?.length > 1) {
			setNodesDetail(n => nodes)
			nodes.forEach(n => {
				n.balance = parseFloat(n.balance).toFixed(0)
			})
			nodes.sort((a,b) =>parseInt(b.balance) - parseInt(a.balance))
			setNodes(n => nodes.length)
			const running = nodes.filter(n => n.running)
			setRunningNodes(n => running.length)
		}
		
	}
	const handleDataTable = (nodes: nodeType[]) => {
		const totalPages = Math.ceil(nodes.length / 10);
		setTotalPage(totalPages)
		const startIndex = (currentPage - 1) * 10;
		const endIndex = startIndex + 10;
		const currentData = nodes.slice(startIndex, endIndex);
		setNodesDetailPage(n => currentData)


	}
	
	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	  };
	const Map = () => {
		let dataMap: {
			country: string;
			value: number;
		}[] = [];
		if (nodesDetail.length) {
			let countryCount: { [key: string]: number } = {};
			
    		nodesDetail.forEach(({ country }) => {
        
				if (country !== undefined) {
					countryCount[country] = countryCount[country] ? countryCount[country] + 1 : 1;
				}
			});

    		dataMap = Object.entries(countryCount).map(([country, value]) => ({ country, value }));

		} 
		return (
			<ItemStylePie>
				{nodesDetail.length && dataMap.length > 0 ?
					<WorldMap
						color={theme.ui.colors.primary}
						value-suffix="people"
						size="lg"
						data={dataMap}
						backgroundColor='transparent'
						frameColor='red'
						borderColor={theme.ui.colors.border.heavy}
						
					/>
					: <></>
				}
			</ItemStylePie>
		);
	}
	useEffect(() => {
		
		handleDataTable(nodesDetail)

	}, [nodesDetail, currentPage])
	useEffect(() => {
        
        const fetchData = async () => {
			if (!active) {
				return
			}
			fetch('https://scan.conet.network/api/v2/stats', {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					'Connection': 'close',
				},
				cache: 'no-store',
				referrerPolicy: 'no-referrer'
			})
			.then ( async res => res.json())
			.then( async data => {
				console.log (data)
				setLastBlock(data.total_blocks)
				setWalletCounting(data.total_addresses)
				const [succes, nodes] = await getAllNodes()
				setDates(nodes[0])
				// initListenState('nodes', data => {
				// 	return setDates(data)
				// })
			})
			const node = loadCSS(
					'https://use.fontawesome.com/releases/v6.5.1/css/all.css',
					// Inject before JSS
					//@ts-ignore
					document.querySelector('#font-awesome-css') || document.head.firstChild)
		  
			  return () => {
				node.parentNode!.removeChild(node)
			}
        }
		
		let active = true
        fetchData()
        return () => { active = false }

    }, [])

	return (
		<>
			<Grid 
				display={{sm: 'none', md: 'flex'}} 
				sx={{ 
					textAlign: 'center', 
					borderRadius: '1rem', 
					// border: '1px solid #E4E2E4', 
					// padding: '0.5rem', 
					minHeight: '324px', 
					width: '100%',
					justifyContent: 'center',
					display: { xs: 'none', md:'flex' }
					}}>
				<Map />
			</Grid>
			<Stack direction={{md: 'row', sm:'column'}} sx={{width: '100%', gap: '16px', marginTop: '24px'}}>
				{/* graphic */}
				<Grid item md={8} sm={8} xs={4} sx={{textAlign: 'center'}}>
					<ItemStylePie sx={{padding: '24px 56px'}} >
						<Stack direction="column" alignItems="start" spacing={1}>
							<Typography variant="body1" sx={{padding: '1rem 0 0 0' }}>
								{intl.formatMessage({id: 'platform.conet.explorer.CNTP'})} 100,000,000
							</Typography>
							<Stack spacing={0} direction="column" alignItems="center" width={'100%'}>
								{PieChart(CNTPMint)}
							</Stack>
							
						</Stack>
					</ItemStylePie>
				</Grid>
				<Grid item md={4} sm={12} sx={{height: '100%', width: '100%'}}>
					<Stack sx={{textAlign: 'center', width: '100%', height: '100%', justifyContent: 'space-between', gap: '16px'}}>
						<ItemStyle>
							<Stack direction="column" alignItems="center" sx={{gap: '16px'}}>
								<Stack spacing={0} direction="row" alignItems="center" >
									<Typography variant="body1" sx={{ fontWeight: '400', fontSize: '36px' }}>
										{lastBlock} / {walletCounting}
									</Typography>
								</Stack>
								<Typography variant="body1" sx={{ fontWeight: '400', fontSize: '16px' }}>
									{intl.formatMessage({id: 'platform.conet.explorer.lastBlock'})}
								</Typography>
							</Stack>
						</ItemStyle>
						<ItemStyle>
							<Stack direction="column" alignItems="center" sx={{gap: '16px'}}>
								<Stack spacing={0} direction="row" alignItems="center">
									<Typography variant="body1" sx={{ fontWeight: '400', fontSize: '36px' }}>
										{nodes} / {runningNodes}
									</Typography>
								</Stack>
								<Typography variant="body1" sx={{ fontWeight: '500', fontSize: '16px' }}>
									{intl.formatMessage({id: 'platform.conet.explorer.nodes'})}
								</Typography>
							</Stack>
						</ItemStyle>
						
					</Stack>
				</Grid>
			</Stack>
			{/* Table */}
			<Grid md={12} sm={8} xs={4} sx={{textAlign: 'center', width: '100%', padding: {xs: '0 0 6rem 0', sm:'0 0 3rem 0'}, marginTop: '24px'}}>
				<TableContainer component={Paper} sx={{width: '100%', padding: '24px', border: '1px solid #E4E2E4', borderRadius: '1rem', backgroundColor:'transparent', boxShadow:'none'}}>
					{/* title table */}
					<Stack direction="row"
						justifyContent="flex-start"
						alignItems="start"
						spacing={4}>
						<Typography variant="h5" sx={{ fontWeight: {xs :'700', md:'400'}, fontSize: {xs: '16px', md:'24px'}, padding: '0 0 0 0'}}>
							{intl.formatMessage({id: 'platform.conet.explorer.CNTP.relayNodes'})} 
						</Typography>
					</Stack>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"
					//@ts-ignore
					 sx={{width: '100%'}}>
						<Stack sx={{display: {xs: 'none', md: 'contents'}, width: '100%'}}>
							<TableHead>
								<TableRow>
									<TableCell>Address</TableCell>
									<TableCell>Country</TableCell>
									<TableCell>IP</TableCell>
									<TableCell>Status</TableCell>
								</TableRow>
							</TableHead>
						</Stack>
						<TableBody>
							{
								!nodesDetail.length &&
								<Stack 
									direction="column"
									justifyContent="center"
									alignItems="center"
									spacing={2}
									sx={{minHeight: '5rem', width: '100%'}}
								>
									<CircularProgress />
								</Stack>
							}
							{
								nodesDetailPage.map(n => (
									<TableRow
										key={n.ip_addr}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" style={{padding: '8px'}}>
											<Link sx={{textDecoration: 'none', display: {xs: 'none', md: 'flex'}}} target="_blank" color={theme.ui.colors.text.white} href={'https://scan.conet.network/address/'+n.minerAddr+'?tab=token_transfers'}>
												{/* {n.minerAddr.substring(0,7)+'...'+ n.minerAddr.substring(37)} */}
												{n.minerAddr} 
											</Link>
											<Stack sx={{display: {xs: 'flex', md: 'none'}}}>
												<Link sx={{textDecoration: 'none'}} target="_blank" color={theme.ui.colors.text.white} href={'https://scan.conet.network/address/'+n.minerAddr+'?tab=token_transfers'}>
													{n.minerAddr.substring(0,7)+'...'+ n.minerAddr.substring(37)}
													{/* {n.minerAddr}  */}
												</Link>
												
												<Link sx={{textDecoration: 'none'}} target="_blank" color={theme.ui.colors.text.neutral} href={'https://dnschecker.org/ip-location.php?ip='+n.ip_addr}>
													{n.ip_addr}
												</Link>
											</Stack>
											
										</TableCell>
										<TableCell component="th" scope="row">
											{countryGetIcon(n.country)}
										</TableCell>
										<TableCell component="th" scope="row">
											<Link sx={{textDecoration: 'none', display: {xs: 'none', md: 'flex'}}} target="_blank" color={theme.ui.colors.text.neutral} href={'https://dnschecker.org/ip-location.php?ip='+n.ip_addr}>
												{n.ip_addr}
											</Link>
										</TableCell>
										{/* <TableCell component="th" scope="row">
											<Icon baseClassName="fa-solid" className="fa-gear fa-spin" sx={{ fontSize: 13, color: '#6e7b63' }} />
										</TableCell> */}
										<TableCell component="th" scope="row" align="right">
											<Stack direction="row" alignItems="center" sx={{width: '100%'}} justifyContent="flex-start">
												
													<Typography variant="body2" sx={{ padding: '0rem 0 0 0', textAlign: 'right'}} color={theme.ui.colors.text.white}>
														<span style={{color: n.running ? ' #577DFF' : '#F03100'}}>&bull;</span> {n.running ? ' active' : 'inactive'}
													</Typography>
												
												{/* <LogoIcon size={15} color={green[300]}/> */}
											</Stack>
												
										</TableCell>
										
									</TableRow>
								))
							}
						</TableBody>
					</Table>
				</TableContainer>
				<Stack 
					sx={{
						flexDirection: 'row', 
						width: '100%', 
						justifyContent: 'center', 
						gap: '16px', 
						alignItems: 'center',
						fontSize: '14px',
						marginY: '16px'
					}}>
					<Typography color={theme.ui.colors.text.neutral} sx={{cursor: 'pointer'}} onClick={() => handlePageChange(1)}>
						First
					</Typography>
					<Typography sx={{fontWeight: 700}}>
						Page {currentPage}-{totalPage}
					</Typography>
					<Stack 
						sx={{
							width: '30px', 
							height: '30px', 
							borderRadius: '5px', 
							backgroundColor: currentPage > 1 ? theme.ui.colors.pagination.active : theme.ui.colors.pagination.inactive,
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'pointer'
						}}
						onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
						>
						<IconArrow color={currentPage > 1  ? 'white' : theme.ui.colors.pagination.icon} />
					</Stack>
					<Stack 
						sx={{
							width: '30px', 
							height: '30px', 
							borderRadius: '5px', 
							backgroundColor: currentPage < totalPage ? theme.ui.colors.pagination.active : theme.ui.colors.pagination.inactive,
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'pointer'
						}}
						onClick={() => currentPage < totalPage && handlePageChange(currentPage + 1)}
						>
						<IconArrow style={{rotate: '180deg'}} color={currentPage < totalPage  ? 'white' : theme.ui.colors.pagination.icon} />
					</Stack>
					<Typography sx={{fontWeight: 700, cursor: 'pointer'}} onClick={() => handlePageChange(totalPage)}>
						Last
					</Typography>
			
				</Stack>
				
			</Grid>
		</>
	)
}

const NodeExplorer = () => {
	const theme = useTheme()
	const intl = useIntl()
	return (
		<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12}} sx={{padding: '0rem 0 10rem 0', width: '100%'}} style={{background:theme.ui.colors.backgroundDashboard, maxWidth:'1200px', boxShadow:'none', padding:'0 16px'}}>
			<Grid  md={12} sm={8} xs={4} sx={{textAlign: 'left', width: '100%', padding: '0 0 2rem 0'}}>
				<Typography sx={{ fontWeight: '400', fontSize: '32px'}}>
						{intl.formatMessage({id: 'platform.conet.explorer.title'})}
				</Typography>
			
			</Grid>
			< Overview/>
			
		</Grid>
	)
}

export default NodeExplorer