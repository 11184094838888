import { Typography, SvgIcon, IconButton, Link, Tooltip, Skeleton } from "@mui/material";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import {
  IoArrowForwardCircleOutline,
  IoArrowUpCircleOutline,
} from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { useTheme } from "styled-components";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { loadCSS } from "fg-loadcss";

import {
  cntp_address,
  initListenState,
  scanAssets,
} from "../../../../../API/index";
import { getWorkerService } from "../../../../../services/workerService/workerService";
import {
  CardContent,
  CardContentTop,
  CardSection,
  DashboardCard,
  ClaimBox
} from "../styles/Dashboardpanel";
import useAppState from "../../../../../store/appState/useAppState";
import { CopyToClipboard } from "../../../../../utilities/utilities";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineContentCopy } from "react-icons/md";

import { ReactComponent as infoIcon } from '../../../../../assets/icons/Info.svg'
import { ReactComponent as helpIcon } from '../../../../../assets/icons/Help.svg'
import { ReactComponent as helpIconLight } from '../../../../../assets/icons/Help_light.svg'

import { Stack, Box } from '@mui/material'

import store, { useTypedSelector } from '../../../../../store/store';
import { platform } from "../../../../../API/platform";
import { useFetchData } from "../../../../../API/refresh";

const formatIpAddr = (addr: string) =>
  addr.substring(0, 2) +
  addr.substring(2, 6).toUpperCase() +
  "...." +
  addr.substring(addr.length - 4, addr.length).toUpperCase();

const DashBoardpanel = () => {
  const currentProfile = () => {
    const workerService = getWorkerService();
    if (workerService.data.status === "LOCKED") {
      return null;
    }

    //console.log("Current PRofile", useTypedSelector(state => state.appState.activeProfile))
    // const index = workerService.data.profiles.findIndex((n: any) => {
    //   return n.isPrimary;
    // });

    return useTypedSelector(state => state.appState.activeProfile);

    // return workerService.data;
  };

  const profile = currentProfile();
  const [showMetaMask, setShowMetaMask] = useState(false);
  const [cntp, setCntp] = useState(profile.tokens?.cntp?.balance || 0);
  const [cntpb, setCntpb] = useState(profile.tokens?.cntpb?.balance || 0)
  const [todayCNTP, setTodayCNTP] = useState("0");
  const [loadingError, setLoadingError] = useState(false);
  const { locale } = useAppState();
  const theme = useTheme();
  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const conet_platform = new platform(setConetPlatform, setWorkerLoading)
  const authKey = useTypedSelector(state => state.appState.pass)

  const data = useFetchData()

  useEffect(() => {
    const fetchData = async () => {
      if (!active) {
        return;
      }
      if (
        typeof (
          //@ts-ignore
          window.ethereum
        ) !== "undefined"
      ) {
        setShowMetaMask(false);
      }
      const node = loadCSS(
        "https://use.fontawesome.com/releases/v6.5.1/css/all.css",
        // Inject before JSS
        //@ts-ignore
        document.querySelector("#font-awesome-css") || document.head.firstChild
      );

      scanAssets();
      initListenState("cntp-balance", (data) => {
        setCntp(data.CNTP_Balance);
        setTodayCNTP(data.currentCNTP);
      });
      return () => {
        active = false;
        node.parentNode!.removeChild(node);
      };
    };

    let active = true;
    fetchData();
  }, []);

  const intl = useIntl();

  const addMateMask = async () => {
    try {
      //	@ts-ignore
      const result = await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x36CB1",
            rpcUrls: ["https://holeskyrpc1.conet.network"],
            chainName: "CONET Sebolia",
            nativeCurrency: { name: "CONET", symbol: "CONET", decimals: 18 },
            blockExplorerUrls: ["https://scan.conet.network/"],
          },
        ],
      });
      //	@ts-ignore
      const wasAdded = await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: cntp_address, // The address that the token is at.
            symbol: "CNTP", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: "", // A string url of the token logo
          },
        },
      });
      console.log(`window.ethereum.request success! result =`, result);
    } catch (ex) {
      console.log(`window.ethereum.request error`, ex);
    }
  };

  const reloading = () => {
    setLoadingError(false);
  };

  const workerService = getWorkerService();
  //console.log('CURRENT', currentProfile)
  return (
    <DashboardCard>
      <CardSection style={{ minWidth: '65%' }}>
        <CardContent gap="0.5rem" align="flex-end">
          <Typography
            sx={{ fontSize: `15px`, fontWeight: `600`, mr: `0.5rem` }}
          >
            {intl.formatMessage({ id: "platform.miner.register.allWallets" })}
          </Typography>
          <Typography sx={{ fontSize: `15px` }}>
            {intl.formatMessage({ id: "platform.miner.register.walletsCount" })}
            :
          </Typography>
          <Typography sx={{ fontSize: `15px`, ml: `-.25rem` }}>
            {data.wallet.length ? data.wallet.length : <Skeleton variant="rectangular" width={24} height={16} />}
          </Typography>
        </CardContent>

        <CardContentTop gap="1rem" >
          <Stack gap={2} style={{ minWidth: '70%' }} >
            <CardContent
              direction="column"
              bg={theme.ui.colors.background.linear}
              color={theme.ui.colors.text.inverted}
              padding="1rem"
              flex="7"
              maxH="5.5rem"
            >
              <Typography sx={{ fontSize: `15px`, display: 'flex', alignItems: 'center', gap: 1 }} >
                {intl.formatMessage({
                  id: "platform.miner.register.currentBalance",
                })}
                <Tooltip title='The CNTP you already claimed'>
                  <SvgIcon component={infoIcon} inheritViewBox fontSize="small" />
                </Tooltip>
              </Typography>

              <Typography
                sx={{ fontWeight: `600`, fontSize: `24px`, letterSpacing: `1px` }}
              >
                {loadingError ? (
                  <IconButton color="error" onClick={reloading}>
                    <SyncProblemIcon />
                  </IconButton>
                ) : (
                  data.wallet.reduce((acc, cur) => acc + parseFloat(cur.tokens?.cntp?.balance), 0)
                  //profile.tokens?.cntp.balance
                )}

                {/* {showMetaMask && (
                  <IconButton color="default" onClick={addMateMask}>
                    <MetamaskIcon />
                  </IconButton>
                )} */}
              </Typography>
            </CardContent>

            <ClaimBox
              direction="row"
              bg={localStorage.getItem('mui-mode') === 'light' ? "#F3F0F2" : "#1B1B1D"}
              color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}
              padding="1rem"
              flex="7"
              maxH="5.5rem"
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Typography sx={{ fontSize: `12px`, color: '#787679', display: 'flex', alignItems: 'center', gap: 1 }}>
                  Unclaimed Balance
                  <Tooltip title='Claim your earned CNTP every 12 hours'>
                    <SvgIcon style={{ marginLeft: '4px' }} component={localStorage.getItem('mui-mode') === 'light' ? helpIconLight : helpIcon} inheritViewBox fontSize="small" />
                  </Tooltip>
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', letterSpacing: `1px` }}
                >
                  {cntpb}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Typography sx={{ fontSize: `12px`, color: '#787679' }}>
                  Claim in
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', letterSpacing: `1px` }}
                >
                  11:58
                </Typography>
              </Box>

              <Typography sx={{ fontWeight: '700', fontSize: `14px`, color: '#787679' }}>
                CLAIM NOW
              </Typography>

            </ClaimBox>
          </Stack>

          <CardContent
            direction="column"
            bg={theme.ui.colors.background.linear}
            color={theme.ui.colors.text.inverted}
            padding="1rem"
            maxH="5.5rem"
            flex="2"
            minW="8rem"
          >
            <Typography sx={{ fontSize: `15px` }}>
              {intl.formatMessage({ id: "platform.joinUS.miner.cloudTitle" })}
            </Typography>
            <Typography
              sx={{ fontWeight: `600`, fontSize: `24px`, letterSpacing: `1px` }}
            >
              {loadingError ? (
                <IconButton color="error" onClick={reloading}>
                  <SyncProblemIcon />
                </IconButton>
              ) : (
                profile.tokens?.cntp?.history?.length ||
                0 + profile.tokens?.conet?.history?.length ||
                0
              )}

              {showMetaMask && (
                <IconButton color="default" onClick={addMateMask}>
                  <MetamaskIcon />
                </IconButton>
              )}
            </Typography>
          </CardContent>
        </CardContentTop>
      </CardSection>

      <CardSection>
        <CardContent gap="1rem" align="flex-end">
          <CardContent>
            <Typography sx={{ fontSize: `15px`, fontWeight: `600`, mr: '16px' }}>
              {intl.formatMessage({
                id: "platform.miner.register.currentWallet",
              })}
            </Typography>
            {/*           <CardContent style={{cursor:'pointer'}} gap="0.65rem" align="center"  onClick={() => CopyToClipboard(addr)}> */}

            <div
              style={{
                display: `flex`,
                color: theme.ui.colors.primary,
                cursor: "pointer"
              }}
              onClick={() =>
                CopyToClipboard(profile.keyID)}
            >

              <Typography
                sx={{ fontSize: `15px`, color: theme.ui.colors.primary }}
              >
                {formatIpAddr(profile.keyID)}
              </Typography>


              <MdOutlineContentCopy style={{ marginLeft: '4px' }} />
            </div>
          </CardContent>
        </CardContent>
        <Stack gap={2}>
          <CardContent gap="1rem">

            <CardContent
              direction="column"
              bg={theme.ui.colors.background.linear}
              color={theme.ui.colors.text.inverted}
              padding="1rem"
              flex="5"
              maxH="5.5rem"
            >
              <Typography sx={{ fontSize: `15px` }}>
                Account Balance
              </Typography>
              <Typography
                sx={{ fontWeight: `600`, fontSize: `24px`, letterSpacing: `1px` }}
              >
                {loadingError ? (
                  <IconButton
                    color="error"
                    sx={{ marginTop: "-0.5rem" }}
                    onClick={reloading}
                  >
                    <SyncProblemIcon />
                  </IconButton>
                ) : (
                  profile.tokens?.cntp.balance
                  //data.wallet.reduce((acc, cur) => acc + parseFloat(cur.tokens?.cntp?.balance), 0)
                )}
              </Typography>
            </CardContent>
          </CardContent>
          <Box minHeight={'68px'}>

          </Box>
        </Stack>
      </CardSection>

    </DashboardCard >
  );
};
export default DashBoardpanel;

const MetamaskIcon = () => (
  <SvgIcon sx={{ width: "1rem", height: "1rem" }}>
    <path
      d="M21.004 2.61 13.2 8.432l1.443-3.434 6.36-2.386Z"
      fill="#E2761B"
    ></path>
    <path
      d="m2.988 2.61 7.741 5.876-1.372-3.489-6.369-2.386Zm15.208 13.492L16.117 19.3l4.447 1.229 1.279-4.356-3.647-.07Zm-16.032.071 1.271 4.356L7.882 19.3l-2.078-3.198-3.64.071Z"
      fill="#E4761B"
    ></path>
    <path
      d="m7.631 10.7-1.24 1.882 4.417.197-.157-4.765L7.63 10.7Zm8.73 0L13.3 7.959l-.101 4.82 4.408-.197-1.248-1.883Zm-8.479 8.6 2.651-1.3-2.29-1.795-.36 3.095ZM13.46 18l2.658 1.3-.368-3.095L13.459 18Z"
      fill="#E4761B"
    ></path>
    <path
      d="M16.117 19.3 13.458 18l.212 1.741-.023.733 2.47-1.174Zm-8.235 0 2.47 1.174-.015-.733.196-1.74-2.65 1.299Z"
      fill="#D7C1B3"
    ></path>
    <path
      d="M10.392 15.055 8.18 14.4l1.561-.717.65 1.37Zm3.208 0 .65-1.37 1.57.716-2.22.654Z"
      fill="#233447"
    ></path>
    <path
      d="m7.882 19.3.377-3.198-2.455.071L7.882 19.3Zm7.859-3.198.376 3.198 2.079-3.127-2.455-.07Zm1.867-3.52-4.408.197.408 2.276.65-1.37 1.57.716 1.78-1.82Zm-9.428 1.82 1.569-.718.643 1.37.416-2.275-4.416-.197 1.788 1.82Z"
      fill="#CD6116"
    ></path>
    <path
      d="m6.392 12.582 1.85 3.623L8.18 14.4l-1.788-1.82Zm9.435 1.82-.078 1.803 1.859-3.623-1.78 1.82Zm-5.02-1.623-.415 2.276.518 2.686.117-3.537-.22-1.425Zm2.393 0-.212 1.417.094 3.545.526-2.686-.408-2.276Z"
      fill="#E4751F"
    ></path>
    <path
      d="m13.608 15.055-.526 2.686.377.26 2.29-1.796.078-1.804-2.22.654ZM8.18 14.4l.063 1.804L10.533 18l.377-.26-.518-2.685L8.18 14.4Z"
      fill="#F6851B"
    ></path>
    <path
      d="m13.647 20.474.023-.733-.196-.173h-2.957l-.18.173.016.733-2.47-1.174.862.709 1.749 1.22h3.004l1.757-1.22.862-.709-2.47 1.174Z"
      fill="#C0AD9E"
    ></path>
    <path
      d="m13.459 18-.377-.26H10.91l-.377.26-.196 1.741.18-.173h2.957l.196.173-.211-1.74Z"
      fill="#161616"
    ></path>
    <path
      d="M21.333 8.81 22 5.595l-.996-2.985-7.545 5.623L16.36 10.7l4.102 1.206.91-1.064-.392-.283.628-.575-.487-.378.628-.48-.416-.316ZM2 5.595l.666 3.213-.423.315.627.48-.478.379.627.575-.392.283.902 1.064 4.102-1.206 2.902-2.465L2.988 2.61 2 5.596Z"
      fill="#763D16"
    ></path>
    <path
      d="M20.462 11.904 16.361 10.7l1.247 1.883-1.86 3.623 2.448-.032h3.647l-1.38-4.269ZM7.632 10.7l-4.103 1.205-1.365 4.27h3.64l2.439.03-1.851-3.622 1.24-1.883Zm5.568 2.08.259-4.545 1.192-3.237H9.357l1.176 3.237.275 4.545.094 1.433.008 3.529h2.172l.016-3.529.102-1.433Z"
      fill="#F6851B"
    ></path>
  </SvgIcon>
);
