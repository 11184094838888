import React from 'react';
import { Container, Typography, InputBase, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { styled as materialStyled } from '@mui/material'

const CardStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
    borderRadius: '0.5rem',
    border: '1px solid #E4E2E4',
    width: '120px',
    height: '40px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center',
    padding: '8px 16px'
}))
const InputStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    borderRadius: '1rem',
    border: '1px solid #E4E2E4',
    maxWidth: '380px',
    width: '100%',
    height: '52px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center'
}))

const PasswordResetForm = ({
    loading,
    show,
    confirmShow,
    password,
    confirmPassword,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleClick,
    handleConfirmClick,
    passwordError,
    passwordFocused,
    confirmPasswordFocused,
    setPasswordFocused,
    setConfirmPasswordFocused
}: any) => {

    return (
        <Container sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '.5rem', padding: { xs: '0', md: '1rem' }, mt: '2rem' }}>
            <Container sx={{ padding: '0!important' }}>
                <Typography style={{ fontSize: '16px', fontWeight: '400' }}>New password</Typography>
                <InputStyle>
                    <InputBase
                        sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                        disabled={loading}
                        id="outlined-password-input"
                        type={show ? 'text' : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        onBlur={() => setPasswordFocused(false)}
                        error={passwordError}
                    />

                    <IconButton tabIndex={-1} onClick={handleClick}>
                        {show ?
                            <VisibilityOutlinedIcon /> :
                            <VisibilityOffOutlinedIcon />
                        }
                    </IconButton>
                </InputStyle>
            </Container>
            <Container sx={{ padding: '0!important' }}>
                <Typography style={{ fontSize: '16px', fontWeight: '400' }}>Confirm password</Typography>
                <InputStyle>
                    <InputBase
                        sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                        disabled={loading}
                        id="outlined-password-input"
                        type={confirmShow ? 'text' : "password"}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        onBlur={() => setConfirmPasswordFocused(false)}
                        error={passwordError}
                    />
                    <IconButton tabIndex={-1} onClick={handleConfirmClick}>
                        {confirmShow ?
                            <VisibilityOutlinedIcon /> :
                            <VisibilityOffOutlinedIcon />
                        }
                    </IconButton>
                </InputStyle>
            </Container>
        </Container>
    );
};

export default PasswordResetForm;