import { Box, Checkbox, MenuItem, Select, Stack, SvgIcon, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as minusIcon } from "../../../../../assets/icons/minusIcon.svg";
import { ReactComponent as plusIcon } from "../../../../../assets/icons/plusIcon.svg";

import { ReactComponent as minusIconBlack } from "../../../../../assets/icons/minus_icon_black.svg";
import { ReactComponent as plusIconBlack } from "../../../../../assets/icons/plus_icon_black.svg";
import { ReactComponent as Lock } from "../../../../../assets/icons/lock.svg";

import pay from "../../../../../assets/images/tether-usdt-seeklogo 1.png";
import coin from "../../../../../assets/images/usd-coin-usdc-logo 1.png";
import eth from "../../../../../assets/images/ethereum-eth 1.png";
import coin3 from "../../../../../assets/images/coin3.png";
import coin4 from "../../../../../assets/images/coin4.png";
import { PurchaseButtom } from "../../miner/styles/SuperNodeBanner";
import { useState } from "react";

interface IPurchaseModel {
    certificateNumber: number;
    setCertificateNumber: React.Dispatch<React.SetStateAction<number>>;
    selectedCertificateCoin: string;
    setSelectedCertificateCoin: React.Dispatch<React.SetStateAction<string>>;
}

const PurchaseModal = (props: IPurchaseModel) => {

    const [isAgreementSigned, setIsAgreementSigned] = useState<boolean>(false)
    const [isAgreementOpened, setIsAgreementOpen] = useState<boolean>(false)

    const handleChange = (event: any) => {
        props.setSelectedCertificateCoin(event.target.value);
    };

    return (
        <Box borderRadius="16px" display="flex" flexDirection="column" gap="16px" border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} bgcolor={localStorage.getItem('mui-mode') === 'light' ? 'white' : '#262527'} padding="24px" width={{ xs: '100%', sm: '440px' }}>
            <Stack direction="column" spacing={4} color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}>
                <Stack>
                    <Typography
                        fontSize="24px"
                        color="#8DA8FF"
                        fontWeight={'700'}
                    >
                        Become a Guardian
                    </Typography>
                </Stack>

                <Box>
                    <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                        Select Account
                    </Typography>
                    <Box position="relative" display="flex" flexDirection="column" pb="24px">
                        <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                            <Box display="flex" gap="8px" alignItems="center">
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'700'}>
                                        Account 04
                                    </Typography>
                                    <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                        0x412BA4...03AB46
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                            Select Quantity
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '14px', mb: '12px' }}>
                            Max. amount: <span style={{ fontWeight: '700' }}>2</span>
                        </Typography>
                    </Box>
                    <Box position="relative" display="flex" flexDirection="column" pb="24px">
                        <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                            <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? minusIconBlack : minusIcon} inheritViewBox fontSize="large" onClick={() => { props.setCertificateNumber(props.certificateNumber > 1 ? props.certificateNumber - 1 : 1); props.certificateNumber > 1 ? props.setCertificateNumber(props.certificateNumber - 1) : null }} />
                            <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '24px' }}>
                                {props.certificateNumber}
                            </Typography>
                            <SvgIcon
                                component={
                                    localStorage.getItem('mui-mode') === 'light'
                                        ? plusIconBlack
                                        : plusIcon
                                }
                                inheritViewBox
                                fontSize="large"
                                onClick={() => {
                                    props.setCertificateNumber(
                                        props.certificateNumber < 2 ? props.certificateNumber + 1 : 2
                                    );
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                <Stack alignItems="center" width={'100%'}>
                    <Typography width={'100%'} textAlign={'left'} sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>Total</Typography>
                    <Typography fontSize="57px">{props.certificateNumber === 1 ? '1,000' : '1,350'}</Typography>
                </Stack>

                <Box display={'flex'} alignItems={'center'} gap={2} margin={'0 auto'} justifyContent={'center'} mt={'16px'}>
                    <Select
                        id="coin-select"
                        defaultValue="usdt"
                        label='USDT'
                        onChange={handleChange}
                        IconComponent={KeyboardArrowDownIcon}
                        variant="standard"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <MenuItem value="usdt"><img src={pay} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />USDT</MenuItem>
                        <MenuItem value="bnb"><img src={coin4} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px', height: '25px', width: '25px' }} />BNB</MenuItem>
                        <MenuItem value="eth"><img src={eth} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />ETH</MenuItem>
                    </Select>
                </Box>
            </Stack>
            <Box marginTop={'48px'} borderRadius='16px' padding='8px' display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'center'} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#111113"} style={{ cursor: 'pointer' }}>
                <Typography textAlign='center' color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"} onClick={() => setIsAgreementOpen(!isAgreementOpened)}>Agreement</Typography>
                {isAgreementOpened &&
                    <>
                        <Stack fontSize={'14px'} padding={'8px 24px'} gap={2} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                            <Typography fontWeight={700}>IGO (Initial Guardian Offering) AGREEMENT</Typography>
                            <Typography>This IGO Agreement (the "Agreement") is entered into as of Mar 20, 2024, by and CoNET Network Foundation (hereinafter referred to as the "Issuer"), and the undersigned public participant (hereinafter referred to as the "Participant").</Typography>
                            <Typography fontWeight={700}>1. DEFINITIONS</Typography>
                            <Typography>1.1 "IGO" refers to the Initial Guardian Offering conducted by the Issuer for the sale of its CoNET Guardian Plan.</Typography>
                            <Typography>1.2 "CNTP" and "CoNET Super Node" refer to the digital assets issued by the Issuer during the IGO.</Typography>
                            <Typography fontWeight={700}>2. PARTICIPATION</Typography>
                            <Typography>2.1 The Participant acknowledges and agrees to participate in the IGO and purchase Guardian Plan at the price specified in the IGO official CoNET Platform V2.0.</Typography>
                            <Typography>2.2 The Participant acknowledges that the participation in the IGO carries inherent risks, and the Participant is solely responsible for conducting its own research before participating.</Typography>
                            <Typography fontWeight={700}>3. Guardian Plan PRICE AND PAYMENT</Typography>
                            <Typography>3.1 The price per Guardian Plan during the IGO is set at USD$1,000 at the first batch (5,000), USD$1,350 at the second batch (5,000), USD$1,850 at the third batch (5,000), Total 15,000 Guardian Plans. Payables in USDT/USDC/USDB/BUSD.</Typography>
                            <Typography>3.2 During the IGO phase, Guardian Plans are available for presale to users worldwide, including all CoNET community members. Certain community users who purchase Guardian Plan on the official CoNET Platform are entitled to a one-year repurchase agreement.</Typography>
                            <Typography>3.3  The project team is committed to ensuring the investment security of purchasers of the "Guardian Plan" and promises a repurchase at cost.</Typography>
                            <Typography>After one-year successful IGO purchase, the Guardian Plan buyer can execute a repurchase agreement through a smart contract at 100% of the purchase price in USDT assets.</Typography>
                            <Typography>Repurchase Lock-up Period: Within one year from the date of purchase of the "Guardian Plan".</Typography>
                            <Typography>During the repurchase lock-up period, if the CoNET token ($CONET) is successfully listed on one of the exchanges such as Coinbase, Binance, OKX, or BitGet, Guardians can be given a 7-day cooling-off period to decide whether they need to be repurchased. If the cooling-off period has passed and the Guardians have not activated the repurchase, the system will automatically assume that the Guardians have waived the right to be repurchased.</Typography>
                            <Typography>After activation for repurchase, all related Guardian rights automatically disappear, but CoNET tokens ($CONET) that are exchanged for referral points ($CNTP), Blast airdrop tokens, and CoNET portable hardware mining machines can be retained. CoNET tokens ($CONET) that are exchanged for super node points ($CNTP) will be destroyed. Super nodes will return to the ownership of the CoNET project. Portable hardware mining machines can continue to participate in POS mining, but the hash rate will be the same as that of standard users.</Typography>
                            <Typography>3.4 Neither party has the authority to propose changes of this repurchase agreement. Once the agreement is established and confirmed, the smart contract for the IGO sale and repurchase process will be automatically executed.</Typography>
                            <Typography>3.5 IGO is an early-stage venture for the project, and both the subscriber and repurchaser voluntarily engage in this activity. Once the contract is executed, subsequent processes will be automatically carried out by smart contract. The return on investment for all IGO users may fluctuate once the token is priced on the market.</Typography>
                            <Typography>3.6 The Participant agrees to pay the total amount in full at the time of participation.</Typography>
                            <Typography fontWeight={700}>4. RESPONSIBILITIES OF THE ISSUER</Typography>
                            <Typography>4.1 The Issuer shall use its best efforts to conduct the IGO in a fair and transparent manner.</Typography>
                            <Typography>4.2 The Issuer makes no guarantees regarding the future value of the Tokens, and the Participant acknowledges the speculative nature of investing in digital assets.</Typography>
                            <Typography fontWeight={700}>5. RISKS</Typography>
                            <Typography>5.1 The Participant acknowledges that the purchase of Guardian Plans involves risks, including but not limited to market risks, regulatory risks, and technology risks.</Typography>
                            <Typography>5.2 The Participant understands and accepts that the value of Guardian Plans may fluctuate, and there is a risk of losing the entire investment.</Typography>
                            <Typography fontWeight={700}>6. COMPLIANCE WITH LAWS</Typography>
                            <Typography>6.1 The Participant agrees to comply with all applicable laws and regulations in their jurisdiction regarding the purchase and possession of Guardian Plans.</Typography>
                            <Typography fontWeight={700}>7. DISCLAIMERS</Typography>
                            <Typography>7.1 The Issuer disclaims any warranties, express or implied, regarding the Guardian Plans, Super Node and the IGO, including but not limited to merchantability and fitness for a particular purpose.</Typography>
                            <Typography fontWeight={700}>8. MISCELLANEOUS</Typography>
                            <Typography>8.1 This Agreement constitutes the entire understanding between the parties and supersedes all prior agreements.</Typography>
                            <Typography>8.2 Amendments to this Agreement must be in writing and signed by both parties.</Typography>
                            <Typography>IN WITNESS WHEREOF, the parties hereto have executed this IGO Agreement as of the date first above written.</Typography>
                            <Typography>CoNET Foundation</Typography>
                            <Typography>Mar 20, 2024</Typography>
                        </Stack>


                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                            <Checkbox checked={isAgreementSigned} onChange={() => setIsAgreementSigned(!isAgreementSigned)} ></Checkbox>
                            <Typography style={{ fontSize: '12px', marginTop: '5px', cursor: 'pointer' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>I agree with the terms and conditions above</Typography>
                        </Box>
                    </>

                }


            </Box>


            <Stack flex="1" direction="column" justifyContent="flex-end">
                <PurchaseButtom
                    style={{
                        width: '100%',
                        textAlign: "center",
                        maxWidth: '300px',
                        margin: '0 auto',
                        marginTop: '32px',
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "600",
                        fontSize: "15px",
                        padding: "0.65rem",
                        borderRadius: "0.75rem",
                        cursor: "pointer",
                    }}

                    // disabled={!isAgreementSigned}

                    disabled={!isAgreementSigned}
                    onClick={() => { console.log('clicado') }}
                >
                    Purchase
                </PurchaseButtom>

                <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                    <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                    Secure payment
                </Stack>
            </Stack>
        </Box>
    )
}
export default PurchaseModal