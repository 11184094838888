import { useEffect, useState } from "react";
import { platform } from "./platform";
import { useTypedSelector } from "../store/store";

export function useFetchData() {
  const [state, setState] = useState({ wallet: [], referees: [] });
  const [conetPlatform, setConetPlatform] = useState<any>("");
  const [workerLoading, setWorkerLoading] = useState(0);
  const conet_platform = new platform(setConetPlatform, setWorkerLoading);
  const authKey = useTypedSelector((state) => state.appState.pass);
  const profile = useTypedSelector((state) => state.appState.activeProfile);

  useEffect(() => {
    const fetchData = async () => {
      // console.log(new Date().getMinutes(), new Date().getSeconds());
      try {
        const profileRes = await conet_platform.getAllProfiles(authKey);

        console.log(profileRes);

        const referList = await conet_platform.getRefereesList(
          authKey,
          profile?.keyID
        );

        setState({ wallet: profileRes, referees: referList });
        //setTimeout(fetchData, 30000);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 35000);

    return () => clearInterval(intervalId);
  }, []);

  return state;
}