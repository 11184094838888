import { SvgIcon, styled as materialStyled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from "react"
import Paper from '@mui/material/Paper'
import { LogoIcon } from "../../../UI/Logo/Logo"
import { Container } from "@mui/material"
import { ButtonLabel, ContainerButton, LinkText, SpanTitle } from "./styles"
import { useTheme } from 'styled-components'
import { ReactComponent as Lightbulb } from "../../../../assets/Lightbulb.svg";
import { LogoDark, LogoLight } from './WelcomeToConet'
import { borderRadius, border, width, height, padding } from '@mui/system'
import { toast } from '../../../UI/Toaster/Toaster'
import { Checkmark } from '../../../UI/Icons/Icons'
import { CopyToClipboard } from '../../../../utilities/utilities'
import { useIntl } from 'react-intl'


interface CreateWalletProps {
    onClickStep: (number: number) => void,
    showSRP: string[]
}
const CopyYourSecretPhrase = ({ onClickStep, showSRP }: CreateWalletProps) => {
    const [loading, setLoading] = useState(false)
    const theme = useTheme()

    const intl = useIntl()

    const CardStyle = materialStyled(Paper)(() => ({
        display: 'flex',
        position: 'relative',
        textAlign: 'center',
        borderRadius: '0.5rem',
        border: '1px solid #E4E2E4',
        width: '120px',
        height: '40px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        justifyContent: 'center',
        padding: '8px 16px'
    }))
    const CardSmallStyle = materialStyled(Paper)(() => ({
        display: 'flex',
        textAlign: 'center',
        borderRadius: '0.5rem',
        border: `1px solid ${theme.ui.colors.primary}`,
        width: '269px',
        height: '24px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        justifyContent: 'center',
        alignItems: 'center'
    }))

    const copyDeviceCode = (event: React.MouseEvent<HTMLButtonElement>, code: string) => {
        event.stopPropagation()
        CopyToClipboard(code)
        toast({
            toastIcon: <Checkmark size={18} />,

            event: intl.formatMessage({ id: 'toaster.action.copyDeviceCode' }),
            duration: 'sm'
        })
    }

    return (
        <Container maxWidth='lg' sx={{ height: '100%', width: '100%', overflowX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: { xs: '1rem', md: '1rem' } }}>
            <Container maxWidth='md' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '576px', padding: 0 }}>
                <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: { xs: '0', md: '1rem' } }}>
                    <LogoLight />
                    <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '14px' }}>Create your Wallet</Typography>
                    <Typography
                        style={{
                            fontSize: '32px',
                            fontWeight: '400',
                            marginTop: '40px'
                        }}
                    >
                        Copy your Secret <SpanTitle>Recovery Phrase</SpanTitle>
                    </Typography>
                    <Typography style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>Your Secret Recovery Phrase makes it easy to back up and restore your account.</Typography>
                </Container>
                <Container>
                    <Container sx={{ display: 'flex', flexWrap: 'wrap', gap: { xs: '8px 12px', md: '12px 16px', lg: '32px 16px' }, justifyContent: 'center', alignItems: 'center', padding: '0px!important', maxWidth: '600px!important', mt: { xs: '8rem', md: '0' } }}>
                        {showSRP.map((item, index) =>
                            <CardStyle key={index}>
                                <Typography style={{ color: theme.ui.colors.primary, fontSize: '16px', fontWeight: '400' }}>{item}</Typography>
                                <span style={{ position: 'absolute', top: -8, left: -1, fontSize: '12px', fontWeight: '700' }}>{index + 1}</span>
                            </CardStyle>
                        )}
                    </Container>
                    <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '28px', paddingX: '0' }}>
                        <CardSmallStyle>
                            <SvgIcon component={Lightbulb} inheritViewBox sx={{ fontSize: 16 }} />
                            <Typography style={{ fontSize: '12px', fontWeight: '500', marginLeft: '4px' }}>Never lose your Secret Recovery Phrase</Typography>
                        </CardSmallStyle>
                    </Container>
                </Container>
                <Container>
                    <Container sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'start', padding: { xs: '0', md: '1rem 8rem' }, gap: '16px', marginTop: '60px' }}>
                        <ContainerButton style={{ width: '210px' }} onClick={(e) => { copyDeviceCode(e, showSRP.join(' ')) }}>
                            <ButtonLabel >Copy phrase</ButtonLabel>
                        </ContainerButton>
                        <LinkText style={{ marginTop: '0', cursor: 'pointer' }} onClick={(e) => onClickStep(2)}>Enter CoNet</LinkText>
                    </Container>
                    <Container sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                        <Typography style={{ maxWidth: '411px', fontSize: '12px', fontWeight: '400', color: theme.ui.colors.text.neutral }}>
                            WARNING: Never disclose your Secret Recovery Phrase. Anyone with this phrase can take your Ether forever.
                        </Typography>
                    </Container>
                </Container>


            </Container>
        </Container>

    )
}

export default CopyYourSecretPhrase