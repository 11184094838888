import styled from "styled-components";

interface IElementProps {
  direction?: string;
  gap?: string;
  justify?: string;
  align?: string;
  padding?: string;
  pointer?: boolean;
  isDisabled?: boolean;
  bg?: string;
  width?: string;
  flex?: string;
  minW?: string;
  minH?: string;
  maxW?: string;
  maxH?: string;
  color?: string;
}

export const DashboardCard = styled.div`
  display: flex;
  border-radius: 1rem;
  flex: 1;
  gap: 2rem;
  margin: 1.5rem 0;
  max-width: 1040px;
  padding:0;
  
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const CardSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.ui.colors.text.primary};
  
  gap: 0.5rem;
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const CardContent = styled.div<IElementProps>`
  display: flex;
  flex: ${(props) => props.flex || `1`};
  border-radius: 0.875rem;
  flex-direction: ${(props) => props.direction || `row`};
  background: ${(props) => props.bg || `transparent`};
  width: ${(props) => props.width || `auto`};
  color: ${(props) => props.color || ``};
  padding: ${(props) => props.padding || `0`};
  gap: ${(props) => props.gap || `0`};
  justify-content: ${(props) => props.justify || ``};
  align-items: ${(props) => props.align || ``};
  min-width: ${(props) => props.minW || `auto`};
  min-height: ${(props) => props.minH || `auto`};
  max-width: ${(props) => props.maxW || `auto`};
  max-height: ${(props) => props.maxH || `auto`};
  cursor: ${(props) => (props.pointer ? `pointer` : `default`)};
  filter: opacity(${(props) => (props.isDisabled ? 0.8 : 1)});


`;


export const CardContentTop = styled.div<IElementProps>`
  display: flex;
  flex: ${(props) => props.flex || `1`};
  border-radius: 0.875rem;
  flex-direction: ${(props) => props.direction || `row`};
  background: ${(props) => props.bg || `transparent`};
  width: ${(props) => props.width || `auto`};
  color: ${(props) => props.color || ``};
  padding: ${(props) => props.padding || `0`};
  gap: ${(props) => props.gap || `0`};
  justify-content: ${(props) => props.justify || ``};
  align-items: ${(props) => props.align || ``};
  min-width: ${(props) => props.minW || `auto`};
  min-height: ${(props) => props.minH || `auto`};
  max-width: ${(props) => props.maxW || `auto`};
  max-height: ${(props) => props.maxH || `auto`};
  cursor: ${(props) => (props.pointer ? `pointer` : `default`)};
  filter: opacity(${(props) => (props.isDisabled ? 0.8 : 1)});
  
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 1rem;
  }

`;


export const ClaimBox = styled.div<IElementProps>`
  display: flex;
  flex: ${(props) => props.flex || `1`};
  border-radius: 0.875rem;
  flex-direction: ${(props) => props.direction || `row`};
  background: ${(props) => props.bg || `transparent`};
  width: ${(props) => props.width || `auto`};
  color: ${(props) => props.color || ``};
  padding: ${(props) => props.padding || `0`};
  gap: ${(props) => props.gap || `0`};
  justify-content: ${(props) => props.justify || ``};
  align-items: ${(props) => props.align || ``};
  min-width: ${(props) => props.minW || `auto`};
  min-height: ${(props) => props.minH || `auto`};
  max-width: ${(props) => props.maxW || `auto`};
  max-height: ${(props) => props.maxH || `auto`};
  cursor: ${(props) => (props.pointer ? `pointer` : `default`)};
  filter: opacity(${(props) => (props.isDisabled ? 0.8 : 1)});
`;
