import { Stack } from "@mui/material";
import styled from "styled-components";

export const SuperNodeBannerWrapper = styled(Stack)`
  display: flex;
  flex-direction: column;
  /* padding: 32px; */
  padding: 0 16px;
  gap: 2rem;
  max-width: 1040px;

  @media (max-width: 600px) {
    padding: 1rem 0 3rem;
  }
`;

export const SuperNodeBannerContent = styled.div<{ hideonmobile?: string }>`
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    display: ${(props) => (props.hideonmobile ? `none` : `flex`)};
  }
`;

export const PurchaseButtom = styled.button`
  background: ${(props) =>
    props.disabled
      ? "#3B3B3D"
      : props.theme.ui.colors.background.linearInverted};
  color: ${(props) =>
    props.disabled ? "#111113" : props.theme.ui.colors.text.inverted};
  border: none !important;
`;

export const SuperNodeBannerBuyCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 25rem;
  justify-content: space-between;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 588px;

  margin-left: 16px;
  /* max-width: 19.375rem; */
  min-width: 19.375rem;
  background: ${(props) => props.theme.ui.colors.background.linearInverted};
  color: ${(props) => props.theme.ui.colors.text.inverted};

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const SuperNodeBannerBuyCardBuying = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  padding: 32px;
  width: 100%;
  max-width: 520px;

  margin-left: 16px;
  color: ${(props) => props.theme.ui.colors.text.inverted};

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin: 0 auto;
  }
`;

export const SuperNodeBannerFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 140px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 1rem;
  gap: 52px;

  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
  }
`;

export const BannerIcon = styled.img`
  overflow: hidden;
  height: 65px;
`;

export const CardNodeBannerFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 210px;
  max-width: 236px;
  height: 113px;
`;
