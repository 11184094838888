import { Stack } from "@mui/material";
import styled from "styled-components";

interface IElementProps {
  direction?: string;
  gap?: string;
  justify?: string;
  align?: string;
  padding?: string;
  pointer?: boolean;
  isDisabled?: boolean;
}

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }
`;

export const InternalHeader = styled(Stack)`
  display: flex;
  /* padding: 0 32px; */
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1040px;
  padding: 0 16px;
  /* @media (max-width: 600px) {
  } */
`;

export const InternalHeaderWelcome = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1040px;
  width: 100%;
  :first-child {
    max-width: 18.75rem;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;
