import React, { useState } from 'react';
import { Select, MenuItem, FormControl, Card, Typography, Box, useColorScheme, SvgIcon, ClickAwayListener, Stack, Paper, PaperTypeMap, Container, IconButton, InputBase } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import ThemeSwitcher from './ThemeSwitcher';
import useAppState from '../../../../../store/appState/useAppState';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CN, JP, US } from 'country-flag-icons/react/3x2';
import { Locale } from '../../../../../localization/types';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import WriteYourSecretPhrase from '../../dashboard/WriteYourSecretPhrase';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { styled as materialStyled } from '@mui/material'
import { ContainerButton, ButtonLabel } from '../../dashboard/styles';
import { platform } from '../../../../../API/platform';
import { toast } from 'react-toastify'

type action = {
  icon: JSX.Element;
  name: Locale;
  description: string;
};

const actions: action[] = [
  {
    icon: <SvgIcon component={JP} inheritViewBox />,
    name: "ja-JP",
    description: "中文 (Soon)",
  },
  {
    icon: <SvgIcon component={CN} inheritViewBox />,
    name: "zh-CN",
    description: "日本語 (Soon)",
  },
  {
    icon: <SvgIcon component={US} inheritViewBox />,
    name: "en-US",
    description: "English",
  },
];

const SettingsCard = () => {
  const { locale, setLocale, theme } = useAppState();
  const [isThemeOpen, setIsThemeOpen] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isPassError, setIsPassError] = useState<boolean>(false);

  const [passwordFocus, setPasswordFocus] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [newPasswordFocus, setNewPasswordFocus] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);

  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const conet_platform = new platform(setConetPlatform, setWorkerLoading)

  const intl = useIntl();

  const languageMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsThemeOpen((prev) => !prev);
  };

  const handleLanguageDropdown = () => {
    console.log(theme)
    setIsThemeOpen(false);
  };

  const handleChangePasswordDropDown = () => {
    setPassword("");
    setShowPassword(false);
    setPasswordFocus(false);
    setNewPassword("");
    setShowNewPassword(false);
    setNewPasswordFocus(false);
    setConfirmPassword("");
    setShowConfirmPassword(false);
    setConfirmPasswordFocus(false);

    setShowChangePassword(!showChangePassword);
  }

  const selectLanguage = (locale: Locale) => {
    //setLocale(locale);
    setIsThemeOpen(false);
  };

  const resetPassword = async () => {
    setIsLoading(true);

    if (newPassword.length == 0 || confirmPassword.length == 0 || password.length == 0) {
      toast.error('Please complete all the fields', { position: "bottom-center" })
    } else {
      if (newPassword === confirmPassword) {
        const pass = await conet_platform.resetPasscode(password, newPassword)

        if (!pass[0]) {
          toast.error('Current password not valid', { position: "bottom-center", autoClose: 1500 })
        } else {
          toast.success('Password has been changed', { position: "bottom-center", autoClose: 1500 })
          // setShowChangePassword(false)
          window.location.reload();
        }

        setIsPassError(true);
      } else {
        toast.error('Please insert matching paswords', { position: "bottom-center", autoClose: 1500 })
        setIsError(true);
      }
    }
    setIsLoading(false);
  }

  const InputStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    borderRadius: '1rem',
    border: '1px solid #E4E2E4',
    maxWidth: '380px',
    width: '100%',
    height: '52px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center'
  }))

  return (
    <Box display="flex" flexDirection="column" gap="8px" marginTop={'26px'} width={'100%'}>
      <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px' }}>
        {intl.formatMessage({ id: 'platform.profile.settingsCard.header.title' })}
      </Typography>
      <Box display="flex" flexDirection="column" borderRadius="16px" padding="16px" border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "white" : "#262527"}>
        <Box display="flex" flexDirection={['column', 'row']} justifyContent="space-between" alignItems="center" height="40px" marginBottom={['15%', '0']}>
          <Typography variant="body1" sx={{ fontWeight: '700', fontSize: '16px' }}>
            {intl.formatMessage({ id: 'platform.profile.settingsCard.themeSwitcherLabel' })}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 8, width: '100%' }}>
            <ThemeSwitcher />
          </div>
        </Box>
        <Box position="relative" display="flex" justifyContent="space-between" alignItems="center" height="40px">
          <Box display="flex" justifyContent="space-between" alignItems="center" width={'100%'} height="40px" onClick={languageMenuClick}>
            <Typography variant="body1" sx={{ fontWeight: '700', fontSize: '16px' }}>
              {intl.formatMessage({ id: 'platform.profile.settingsCard.languageSwitcherLabel' })}
            </Typography>
            <Box display="flex" gap="8px" alignItems="center" style={{ cursor: 'pointer' }}>
              {(actions.find((x) => x.name === locale) as action).description}
              <SvgIcon component={ChevronRightIcon} inheritViewBox sx={{ fontSize: 24 }} />
            </Box>
          </Box>
          {isThemeOpen ? (
            <ClickAwayListener onClickAway={handleLanguageDropdown}>
              <Box
                width={["90vw", "440px"]}
                height="208px"
                position="absolute"
                bottom="30px"
                left={["-10vw", "420px"]}
                zIndex={9999}
                bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
                display="flex"
                flexDirection="column"
                borderRadius="16px"
                boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
                p="1rem"
                gap="32px"
              >
                <Stack justifyContent="space-between" direction="row">
                  <Typography fontWeight="bold">
                    <FormattedMessage
                      id="sidebar.languagetext"
                      defaultMessage="Language"
                    />
                  </Typography>
                  <SvgIcon
                    cursor="pointer"
                    onClick={handleLanguageDropdown}
                    component={CloseIcon}
                    inheritViewBox
                    sx={{ fontSize: 16 }}
                  />
                </Stack>
                <Stack direction="column">
                  {actions.map((language, index) => (
                    <Box key={index} px="1rem" height="40px" width={'100%'} alignItems="center" display="flex" borderRadius="16px" justifyContent="space-between" style={{ cursor: 'pointer' }}>
                      {language.description}
                      {locale === language.name ? (
                        <SvgIcon
                          cursor="pointer"
                          component={CheckIcon}
                          inheritViewBox
                          sx={{ fontSize: 24 }}
                        />
                      ) : null}
                    </Box>
                  ))}
                </Stack>
              </Box>
            </ClickAwayListener>
          ) : null}
        </Box>
        <Box position="relative" display="flex" justifyContent="space-between" alignItems="center" width='100%' height="40px" style={{ cursor: 'pointer' }} >
          <Box display="flex" justifyContent="space-between" alignItems="center" height="40px" onClick={() => setShowChangePassword(!showChangePassword)}>
            <Typography variant="body1" sx={{ fontWeight: '700', fontSize: '16px' }}>
              {/* {intl.formatMessage({ id: 'platform.profile.settingsCard.changepassword', defaultMessage: "Change password" })} */}
              Change Password
            </Typography>
            <SvgIcon component={ChevronRightIcon} inheritViewBox sx={{ fontSize: 24 }} />
          </Box>
          {showChangePassword ? (
            <ClickAwayListener onClickAway={handleChangePasswordDropDown}>
              <Box
                width={['90%', '440px']}
                position='absolute'
                bottom='5vh'
                left={['5vw', '420px']}
                zIndex={9999}
                bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
                display="flex"
                flexDirection="column"
                borderRadius="16px"
                boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
                p="2rem"
                gap="32px"
              >
                <Stack justifyContent="space-between" direction="row">
                  <Typography fontWeight="bold">
                    Change your password
                  </Typography>
                  <SvgIcon
                    cursor="pointer"
                    onClick={handleChangePasswordDropDown}
                    component={CloseIcon}
                    inheritViewBox
                    sx={{ fontSize: 16 }}
                  />
                </Stack>
                <Stack direction="column" gap="40px">

                  <Container sx={{ padding: '0!important' }}>
                    <Typography style={{ fontSize: '16px', fontWeight: '400' }}>Enter your password</Typography>
                    <InputStyle>
                      <InputBase
                        sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                        id="outlined-password-input"
                        type={showPassword ? 'text' : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        onFocus={() => setPasswordFocus(true)}
                        autoFocus={passwordFocus}
                        disabled={isLoading}
                        error={isPassError}
                      />

                      <IconButton tabIndex={-1} onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputStyle>
                  </Container>

                  <Typography style={{ fontSize: '16px', fontWeight: '600' }}>Type your new password</Typography>

                  <Container sx={{ padding: '0!important' }}>
                    <Typography style={{ fontSize: '16px', fontWeight: '400' }}>New password</Typography>
                    <InputStyle>
                      <InputBase
                        sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                        id="outlined-password-input"
                        type={showNewPassword ? 'text' : "password"}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.currentTarget.value)}
                        onFocus={() => setNewPasswordFocus(true)}
                        autoFocus={newPasswordFocus}
                        disabled={isLoading}
                        error={isError}
                      />

                      <IconButton tabIndex={-1} onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputStyle>
                  </Container>

                  <Container sx={{ padding: '0!important' }}>
                    <Typography style={{ fontSize: '16px', fontWeight: '400' }}>Confirm new password</Typography>
                    <InputStyle>
                      <InputBase
                        sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                        id="outlined-password-input"
                        type={showConfirmPassword ? 'text' : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                        onFocus={() => setConfirmPasswordFocus(true)}
                        autoFocus={confirmPasswordFocus}
                        disabled={isLoading}
                        error={isError}
                      />

                      <IconButton tabIndex={-1} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputStyle>
                  </Container>

                </Stack>
                <Stack direction="row" gap="12px">
                  <Box width="193px">
                    <ContainerButton onClick={resetPassword}>
                      <ButtonLabel>Change password</ButtonLabel>
                    </ContainerButton>
                  </Box>
                  <ButtonLabel onClick={handleChangePasswordDropDown}>Cancel</ButtonLabel>
                </Stack>
              </Box>
            </ClickAwayListener>
          ) : null}
        </Box>

      </Box>

    </Box>
  );
};

export default SettingsCard;

