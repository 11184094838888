import { useEffect, useState } from "react";
import { platform } from "./platform";

export function useFetchPrices() {
  const [prices, setPrices] = useState([]);
  const [conetPlatform, setConetPlatform] = useState<any>("");
  const [workerLoading, setWorkerLoading] = useState(0);
  const conet_platform = new platform(setConetPlatform, setWorkerLoading);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const res = await conet_platform.getAssetsPrice();
        setPrices(res);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPrices();
    const intervalId = setInterval(() => {
      fetchPrices();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return prices;
}
