import styled from "styled-components";

export const CommunityLivenessWrapper = styled.div`
  display: flex;
  /* background: ${(props) => props.theme.ui.colors.background.elevationOne}; */
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CommunityLivenessContent = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* padding: 4rem 3rem; */
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;

export const CardLinkPrices = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 291px;
  height: 255px;
  background-color: #363E59;
  border-radius: 1rem;
  padding: 24px;
  position: relative;
  color: ${(props) => props.theme.ui.colors.primary};

  .svgicon {
    position: absolute !important;
    transform: translate(0, -17%);
    opacity: 0.5;
  }
`

export const StartMiningCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 255px;
  flex-direction: column;
  /* min-height: 255px; */
  /* max-width: 19.6rem; */
  /* max-height: 9.875rem;
  min-height: 9.875rem; */
  border-radius: 1rem;
  /* background: ${(props) => props.theme.ui.colors.background.linear}; */
  background: #8DA8FF;
  color: ${(props) => props.theme.ui.colors.text.inverted};
  /* flex: 1; */
  padding: 2rem;
  /* position: relative; */
  cursor:pointer;

  /* .svgicon {
    position: absolute !important;
    right: 0;
    top: 0;
    transform: translate(0, -17%);
  } */
`;

export const StartMiningButton = styled.button<{ isRunning: boolean }>`
  height: fit-content;
  border: none;
  color: ${(props) => props.theme.ui.colors.text.inverted};
  opacity: ${(props) => (props.isRunning ? 0.8 : 1)};

  background: transparent;
  cursor: pointer;
  z-index: 2;
`;
