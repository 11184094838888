import React, { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { cntp_address } from "../../../../../API/index";
import {
  Grid,
  CircularProgress,
  TextField,
  styled as materialStyled,
  TableContainer,
  Typography,
  SvgIcon,
  Fab,
  ButtonProps,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
  Stack,
  Box,
  Slide,
  Button,
  LinearProgress,
  Skeleton,
  Link,
} from "@mui/material";
import { grey, lightGreen, blueGrey, green, red } from "@mui/material/colors";
import {
  startLiveness,
  stopLiveness,
  isLivenessRunning,
} from "../../../../../API/index";
import miner2 from "../../../../../assets/miner/FancyNyan.webp";
import minerPause from "../../../../../assets/miner/FancyNyanPause.png";
import { Tabs, Tab, Button as ButtonNext, Divider } from "@mui/material-next";
import ErrorIcon from "@mui/icons-material/Error";
import {
  CardLinkPrices,
  CommunityLivenessContent,
  CommunityLivenessWrapper,
  StartMiningButton,
  StartMiningCard,
} from "../styles/Community_liveness";
import { BsPlusCircleFill } from "react-icons/bs";
import { useTheme } from "styled-components";
import { ReactComponent as LogoIcon } from "../../../../../assets/logo/logo-transparent.svg";
import { FlexContent } from "../../../../Generic/Flex";
import { FiMinusCircle } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const ItemStyle3 = materialStyled(Paper)(() => ({
  textAlign: "center",
  borderRadius: "1rem",
  padding: "1rem",
  width: "100%",
}));

const ColorButton = materialStyled(Button)<ButtonProps>(({ theme }) => ({
  "&:hover": {},
  fontFamily: ["Inter", '"Inter Placeholder"', "sans-serif"].join(","),
  fontWeight: "900",
  fontSize: "20px",
  padding: "0.5rem 4rem 0.5rem 4rem",
  borderRadius: "2rem",
}));

interface Ratedata {
  ipaddress: number;
  online: number;
  rate: number;
  status: number;
}

interface IProps {
  CNTP: string;
  setCNTP: (v: string) => void;
  setTodayCNTP: (v: string) => void;
}
export const Community_liveness: React.FC<IProps> = ({
  CNTP,
  setCNTP,
  setTodayCNTP,
}) => {
  const intl = useIntl();
  const [showLoader, setShowLoader] = useState(false);
  const [firstLoader, setFirstLoader] = useState(0);
  const [showSameIPError, setShowSameIPError] = useState(false);
  const [showInstanceError, setShowInstanceError] = useState(false);
  const [minting, setMinting] = useState(false);
  const [showTimeOutError, setShowTimeOutError] = useState(false);
  const [regError, setRegError] = useState(false);
  const [rateProgress, setRateProgress] = useState(0);
  const [rateProgressBuffer, setRateProgressBuffer] = useState(0);
  const [rateProgressColor, setRateProgressColor] = useState<
    "success" | "warning" | "error"
  >("success");
  const [onlineCount, setOnlineCount] = useState(0);
  const [onlineRate, setOnlineRate] = useState("");

  const [isLoadingMiner, setIsLoadingMiner] = useState(false);

  const theme = useTheme();

  const formatDat = (rateData: Ratedata) => {
    let process = 0;
    if (rateData.online < 1000) {
      process = (rateData.online / 1000) * 100;
      process = process > 90 ? 90 : process;
      setRateProgressColor("success");
    } else if (rateData.online < 2000) {
      process = (rateData.online / 2000) * 100;
      process = process > 90 ? 90 : process;
      setRateProgressColor("warning");
    } else {
      process = (rateData.online / 10000) * 100;
      process = process > 90 ? 90 : process;
      setRateProgressColor("error");
    }
    setOnlineCount(rateData.online);
    setRateProgress(process);
    setRateProgressBuffer(process + Math.random() * 7);
    const cntpRate = (rateData.rate / (rateData.online * 24)).toFixed(6);
    setOnlineRate(cntpRate);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!active) {
        setIsLoadingMiner(false);
        return;
      }

      if (showLoader) {
        if (minting) {
          await stopLiveness();
          setShowLoader(false);
          setIsLoadingMiner(false);
          // setIslivenessRunning(false)
          return setMinting(false);
        }

        startLiveness((err, data) => {
          if (showLoader) {
            setShowLoader(false);
          }
          if (data[0] === "TIMEOUT") {
            return setShowTimeOutError(true);
          }
          if (data[0] === "sameInstance") {
            return setShowInstanceError(true);
          }

          if (data[0] === "SAMEIP") {
            return setShowSameIPError(true);
          }
          if (data[0] === "FAILURE") {
            return setRegError(true);
          }
          if (!minting) {
            setMinting(true);
          }
          setCNTP(data[0]);
          setTodayCNTP(data[1]);
          //	@ts-ignore
          const rateData: Ratedata = data[2];
          formatDat(rateData);
        });
      }
    };

    let active = true;
    fetchData();
    return () => {
      active = false;
    };
  }, [showLoader]);

  useEffect(() => {
    const fetchData = async () => {
      if (!active) {
        return;
      }
      if (minting) {
        return console.log(`minting is true return!`);
      }

      isLivenessRunning((err, data) => {
        if (!data.length || err) {
          return;
        }

        if (!minting) {
          setMinting(true);
          setCNTP(data[0]);
          setTodayCNTP(data[1]);
          //	@ts-ignore
          const rateData: Ratedata = data[2];
          formatDat(rateData);
        }
      });
    };

    let active = true;
    fetchData();
    return () => {
      active = false;
    };
  }, [minting]);

  const clearError = () => {
    setShowSameIPError(false);
    setShowTimeOutError(false);
    setShowInstanceError(false);
    setIsLoadingMiner(false);
    setRegError(false);
  };

  const clickStart = async () => {
    setIsLoadingMiner(true);

    if (showTimeOutError || showInstanceError) {
      return clearError();
    }
    setShowLoader(true);
  };

  const haveInstanceError = useMemo<boolean>(
    () => showTimeOutError || showInstanceError || showSameIPError || regError,
    [showInstanceError, showSameIPError, regError, showTimeOutError]
  );

  return (
    <CommunityLivenessWrapper>
      <Stack 
        direction={{ base: `column`, sm: "row" }} 
        maxWidth="1040px" 
        width="100%" 
        justifyContent="space-between" 
        alignItems="center" 
        gap='28px'
      >
        <CommunityLivenessContent>
          {regError && (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: red[700], padding: "0 0 1rem 0" }}
            >
              {intl.formatMessage({
                id: "platform.miner.community.liveness.sameIPError",
              })}
            </Typography>
          )}
          {showSameIPError && (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: red[700], padding: "0 0 1rem 0" }}
            >
              {intl.formatMessage({
                id: "platform.miner.community.liveness.sameIPError",
              })}
            </Typography>
          )}
          {showTimeOutError && (
            <Stack spacing={1}>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", color: red[700] }}
              >
                {intl.formatMessage({
                  id: "platform.miner.community.liveness.Timeout1",
                })}
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", color: red[700] }}
              >
                {intl.formatMessage({
                  id: "platform.miner.community.liveness.Timeout2",
                })}
              </Typography>
            </Stack>
          )}
          {showInstanceError && (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: red[700], padding: "0 0 1rem 0" }}
            >
              {intl.formatMessage({
                id: "platform.miner.community.liveness.sameMinerError",
              })}
            </Typography>
          )}
          {/* <Stack spacing={5} marginBottom="1.5rem" style={{display:'flex', alignItems:'center'}}>
            <Typography
              color={theme.ui.colors.primary}
              sx={{ fontSize: `14px`, textTransform: `uppercase` }}
            >
              {intl.formatMessage({ id: "platform.miner.register.goLive" })}
            </Typography>
            {minting ? (
              <img src={miner2} style={{ width: "5rem" }} />
            ) : (
              <img src={minerPause} style={{ width: "5rem" }} />
            )}
          </Stack>
          <Typography variant="h5" sx={{ fontWeight: "800" }}>
            {CNTP} { } {'CNTP'}
          </Typography> */}
          <CardLinkPrices>
            <div className="svgicon">
              <SvgIcon
                component={LogoIcon}
                inheritViewBox
                sx={{ fontSize: `350px`, zIndex: '1' }}
              />
            </div>
            <Link underline="hover" sx={{cursor: 'pointer', zIndex: '10'}} href='#guardian'>
              <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography fontSize={'16px'} fontWeight={500}>Learn More</Typography>
                  <IoIosArrowForward fontSize="14px" />
              </Stack>
            </Link>
            <Stack>
              <Typography color='#fff' textAlign={'left'} fontSize={'24px'} fontWeight={700}>Democratizing the CoNET network</Typography>
              <Typography color='#fff' textAlign={'left'} fontSize={'16px'} fontWeight={400}>Become a Guardian to boost your earnings</Typography>
            </Stack>
            {/* color={theme.ui.colors.text.white} */}

          </CardLinkPrices>
          
        </CommunityLivenessContent>

        <Stack 
          flexDirection={'column'} 
          width={'100%'}
          alignItems={'flex-start'}
          justifyContent={'center'}
        >
          {/* <FlexContent
            direction="column"
            minW="19.6rem"
            justify="center"
            gap="0.75rem"
          >

          </FlexContent> */}
            <StartMiningCard onClick={clickStart}>
              {/* <div className="svgicon">
                <SvgIcon
                  component={LogoIcon}
                  inheritViewBox
                  sx={{ fontSize: `15rem` }}
                />
              </div> */}
              <Stack width={'100%'}>
                {haveInstanceError && (
                  <ColorButton
                    variant="outlined"
                    color="error"
                    endIcon={<ErrorIcon color="error" />}
                    onClick={clickStart}
                    sx={{ borderRadius: "2rem" }}
                  >
                    <Typography sx={{ fontStyle: "inherit" }}>
                      {intl.formatMessage({
                        id: "platform.miner.community.liveness.onlineMiners",
                      })}
                    </Typography>
                  </ColorButton>
                )}
                {/* !haveInstanceError && rateProgress > 0 && minting  */}
                {!haveInstanceError && rateProgress > 0 && minting ? (
                  <Stack sx={{ color: theme.ui.colors.text.inverted }}>
                    <Stack direction="column" sx={{ fontStyle: "inherit" }}>
                      <Stack spacing={1} direction="row">
                        <Typography variant="caption" sx={{ opacity: 0.7 }}>
                          {intl.formatMessage({
                            id: "platform.miner.community.liveness.onlineMiners",
                          })}
                          :
                        </Typography>
                        <Typography
                          variant="caption"
                        >
                          {onlineCount}
                        </Typography>
                      </Stack>
                      <Stack spacing={1} direction="row">
                        <Typography variant="caption" sx={{ opacity: 0.7 }}>
                          {intl.formatMessage({
                            id: "platform.miner.community.liveness.rewordRate",
                          })}
                          :
                        </Typography>
                        <Typography variant="caption">
                          {onlineRate || 0}
                        </Typography>

                        <Typography variant="caption" sx={{ opacity: 0.7 }}>
                          {intl.formatMessage({
                            id: "platform.miner.community.liveness.eachMin",
                          })}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                ) : isLoadingMiner ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height="2rem"
                    sx={{ margin: `auto` }}
                  />
                ) : null}
              </Stack>
              <Stack width={'100%'} flexDirection={'row'} alignItems={'flex-end'}>
                  <Stack style={{display:'flex', alignItems: 'flex-start', width: '100%', maxWidth: '271px'}}>
                  
                    <Typography sx={{ fontSize: '52px', color: theme.ui.colors.text.inverted, fontWeight: '700' }}>
                      {CNTP}
                    </Typography>
                    <Typography sx={{ fontSize: '16px', lineHeight: '1rem' }}>
                      CNTP
                    </Typography>
                      <Box sx={{ width: '100%', marginTop: '8px' }}>
                        <LinearProgress variant="determinate" value={rateProgress} />
                      </Box>
                  </Stack>
                <Stack 
                  color={theme.ui.colors.text.white} 
                  width={'100%'} 
                  flexDirection={'row'} 
                  justifyContent={'flex-end'} 
                  alignItems={'center'}
                  gap='8px'
                >
                  <StartMiningButton isRunning={!!minting}>
                    {!!minting ? 
                      <SvgIcon
                        component={ClearOutlinedIcon}
                        inheritViewBox
                        sx={{ fontSize: `1.2rem`, color: theme.ui.colors.text.white }}
                      /> : 
                      <SvgIcon
                        component={AddOutlinedIcon}
                        inheritViewBox
                        sx={{ fontSize: `1.2rem`, color: theme.ui.colors.text.white }}
                      />}
                  </StartMiningButton>

                  <Typography 
                    sx={{ 
                      fontWeight: "500", 
                      fontSize:'1rem'
                      }}>
                    {!!minting ? intl.formatMessage({ id: "platform.miner.community.stopMining" }) : intl.formatMessage({ id: "platform.miner.community.startMining" })}
                  </Typography>
                </Stack>
              </Stack>
            </StartMiningCard>
            
        </Stack>
      </Stack>
    </CommunityLivenessWrapper>
  );
};
