import { useIntl } from "react-intl"
import { styled as materialStyled, Box, ClickAwayListener, InputBase, Paper, Button, Skeleton, CircularProgress } from '@mui/material'
import styledCom from "styled-components"
import Typography from '@mui/material/Typography'
import useAppState from "../../../../store/appState/useAppState"
import { Stack, SvgIcon } from '@mui/material'
import React, { useState, useEffect } from "react"
import { getWorkerService } from '../../../../services/workerService/workerService'
import CircleIcon from '@mui/icons-material/Circle'
import styled from 'styled-components'
import { toast as toaster } from "../../../UI/Toaster/Toaster"
import { Checkmark } from "../../../UI/Icons/Icons"
import { CopyToClipboard } from "../../../../utilities/utilities"
import { ReactComponent as defaultProfilePicture } from './assets/default_profile.svg'
import { ReactComponent as CONETIcon } from '../../../../assets/logo/CoNET_logo.svg'

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import toSendIcon from "../../../../assets/images/toSendIcon.png"

import Divider from '@mui/material/Divider';

import InfoCard from "./pags/InfoCard"
import SettingsCard from "./pags/SettingsCard"
import { IProfile } from "./interface/profile.interface"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Input } from "@mui/base"
import SearchIcon from '@mui/icons-material/Search';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import store, { useTypedSelector } from "../../../../store/store"
import { platform } from '../../../../API/platform';
import { ButtonLabel, ContainerButton } from "../dashboard/styles"
import { toast } from 'react-toastify'
import NewWalletModal from "./pags/NewWalletModal"
import { setActiveProfile } from "../../../../store/appState/appStateActions"

import { ReactComponent as CloseIcon } from '../../../../assets/icons/Close.svg'
import { ReactComponent as CloseIconBlack } from '../../../../assets/icons/Close_black.svg'
import { PurchaseButtom } from "../miner/styles/SuperNodeBanner"
import PurchaseModal from "./pags/PurchaseModal"

const StyleDiv = styledCom.div`
`

type StyledProfileItemProps = {
    isactive?: boolean
}

const CardStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
    borderRadius: '0.5rem',
    border: '1px solid #E4E2E4',
    width: '120px',
    height: '40px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center',
    padding: '8px 16px'
}))

const StyledCardProfile = styled.div<StyledProfileItemProps>`
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    border-radius: 16px;
    width: 371px;

    &:hover {
        background-color: #f6f8ff; /* Altera a cor de fundo ao passar o mouse */
    }

`;

//border: 0, width: "100%", backgroundColor: "transparent"
const SearchInput = styled.input`
    border: 0px;
    width: 100%;

    &:focus {
        outline: none;
    }
`;

const currentProfile = () => {
    const workerService = getWorkerService()
    if (workerService.data.status === 'LOCKED') {
        return null
    }
    // const index = workerService.data.profiles.findIndex((n:any) => {
    // 	return n.isPrimary
    // })

    return workerService.data
}
const InputStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    marginBottom: '16px',
    borderRadius: '1rem',
    border: '1px solid #E4E2E4',

    width: '100%',
    height: '52px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center'
}))

const ProfileTest = () => {



    const {
        locale,
        setDAPPOpen,
        theme
    } = useAppState()

    const intl = useIntl()

    const [showBosste, setShowBosste] = useState(false)
    const [wallets, setWallets] = useState<any>([])
    const [filteredWallets, setFilteredWallets] = useState<any>()
    const [refresh, setRefresh] = useState<boolean>(false)
    const [showKeyModal, setShowKeyModal] = useState<boolean>(false)
    const [showNewWalletModal, setShowNewWalletModal] = useState<boolean>(false)
    const [privateKey, setPrivateKey] = useState<string>('')
    const [selectedCoin, setSelectedCoin] = useState<string>('CNTP')
    const [coinDropDownOpen, setCoinDropDownOpen] = useState<boolean>(false)
    const [selectedColor, setSelectedColor] = useState<string | undefined>()
    const [cntp, setCntp] = useState<string>('0');
    const [selectedUser, setSelectedUser] = useState('')
    const [selectedTransaction, setSelectedTransaction] = useState('')
    const [users, setUsers] = useState<IProfile[]>([])
    const [currentName, setCurrentName] = useState<string>()
    const [showMetaMask, setShowMetaMask] = useState(false)
    const [purchase, setPurchase] = useState(false)
    const [certificateNumber, setCertificateNumber] = useState<number>(1)
    const [selectedCertificateCoin, setSelectedCertificateCoin] = useState<string>('usdt')
    const [privateKeyArmor, setPrivateKeyArmor] = useState<string>('')
    const [isFaucetLoading, setFaucetLoading] = useState<boolean>(false)
    const { data } = getWorkerService()

    const [currentProfile, setCurrentProfile] = useState<any>();

    const [keyID, setKeyID] = useState<string>();
    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);

    const [isSearch, setIsSearch] = useState(false)

    const [isTransactionHistoryOpen, setTransactionHistoryOpen] = useState(false);

    const profile = useTypedSelector(state => state.appState.activeProfile)
    const [profiles, setProfiles] = useState<any>([])

    const [phrase, setPhrase] = useState<string[]>(Array(12).fill(''));
    const [password, setPassword] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [conetPlatform, setConetPlatform] = useState<any>('')
    const [workerLoading, setWorkerLoading] = useState(0)
    const conet_platform = new platform(setConetPlatform, setWorkerLoading)

    const authKey = useTypedSelector(state => state.appState.pass)
    // console.log(currentProfile)
    useEffect(() => {
        if (data) {
            const _currentProfile = profile;
            //const shortID = keyID.substring(0,2) + keyID.substring(2,6).toUpperCase() + '....' + keyID.substring(keyID.length-4,keyID.length).toUpperCase()
            setCntp(_currentProfile?.tokens?.cntp?.balance)
            setKeyID(_currentProfile?.keyID);
            setSelectedUser(_currentProfile?.keyID);
            setCurrentProfile(profile);
            setUsers(data.profiles);
            setCurrentName(_currentProfile?.data?.nickname ? _currentProfile?.data?.nickname : intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' }))
        }
    }, [data]);


    useEffect(() => {
        if (currentProfile) {
            setCntp(currentProfile.tokens.cntp.balance)
        }
    }, [currentProfile])

    const importOneWallet = async () => {
        setIsLoading(true)
        const w = await conet_platform.importWallet(authKey, privateKey)
        if (w.length > 0) {
            setPrivateKey('')
            setRefresh(!refresh)
            toast.success('Wallet imported', { position: "bottom-center", autoClose: 1500 })
            setShowKeyModal(false)
            setIsLoading(false)
        } else {
            toast.error('Import failed, review your private key')
            setIsLoading(false)
        }
    }


    const transactionMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setTransactionHistoryOpen((prev) => !prev);
    };

    const transacionCloseDropdown = () => {
        setTransactionHistoryOpen(false);
    };

    const profileMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setProfileDropdownOpen((prev) => !prev);
    };

    const profileCloseDropdown = () => {
        setProfileDropdownOpen(false);
    };

    const onSelectProfile = () => {
        setProfileDropdownOpen(false);
    }

    const copyDeviceCode = (event: React.MouseEvent<HTMLButtonElement>, code: string) => {
        event.stopPropagation()
        toaster({
            toastIcon: <Checkmark size={18} />,

            event: intl.formatMessage({ id: 'toaster.action.copyDeviceCode' }),
            duration: 'sm'
        })

        CopyToClipboard(code)
    }
    function selectShowedCoin(coin: string) {
        if (coin === 'CNTP') {
            setSelectedCoin('CNTP');
            setCoinDropDownOpen(false)
            setCntp(profile.tokens.cntp.balance)
        } else {
            setSelectedCoin('CONET Holesky');
            setCoinDropDownOpen(false)
            setCntp(profile.tokens.conet.balance)
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const profiles = await conet_platform.getAllProfiles(authKey);
                setProfiles(profiles);
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        };

        fetchData();
    }, [authKey, refresh])

    useEffect(() => {
        if (currentProfile) {
            if (typeof
                //@ts-ignore
                window.ethereum
                !== 'undefined') {
                setShowMetaMask(false)
            }
            setPrivateKeyArmor(currentProfile.privateKeyArmor)
        }
    }, [currentProfile])


    
    // useEffect(() => {
    //     console.log(conet_platform.C)
    // }, [conet_platform])
    

    async function generateFaucet(key: string) {
        setFaucetLoading(true)
        const res = await conet_platform.CONETFaucet(key)
        if (res) {
            await conet_platform.getAllProfiles(authKey)
            toast.success('You received Faucets!', { position: "bottom-center", autoClose: 1500 })
            setFaucetLoading(false)
        } else {
            toast.error('Please wait 24h until request next faucet', { position: "bottom-center", autoClose: 1500 })
            setFaucetLoading(false)
        }
    }


    const tagColors = [
        "#FF5E5A", "#FFAF00", "#FFDE00", "#00E361", "#009CFF", "#D873FB", "#ACACB0"
    ]

    const [keyProfile, setKeyProfile] = useState<{ index: number; color: string }[]>([]);

    const [filter, setFilter] = useState<string>('');

    const filterWallets = () => {
        let result = profiles.filter((prof: any) => {
            const nameMatches = !!filter.length ? prof.data?.nickname.toLowerCase().includes(filter.toLowerCase()) : true;
            const tagMatches = !!keyProfile.length ? !!keyProfile.find((keyProfile) => keyProfile.color === prof.data?.tags) : true;

            return nameMatches && tagMatches;
        })

        setFilteredWallets(result)
    }
    console.log(refresh)
    useEffect(() => {
        filterWallets();
    }, [filter, keyProfile.length, profiles, refresh])

    const handleWordChange = (index: number, value: string) => {
        const newPhrase = [...phrase];
        newPhrase[index] = value;
        setPhrase(newPhrase);
    };
    const handlePaste = (event, index) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text/plain');
        const words = pastedText.split(' ');
        const newInputValues = [...phrase];
        words.forEach((word, i) => {
            if (i < newInputValues.length) {
                newInputValues[i] = word;
            }
        });
        setPhrase(newInputValues);
    };

    const handleIconClick = (key: number, color: string) => {
        const isKeySelected = keyProfile.some((k) => k.index === key && k.color === color);

        if (isKeySelected) {
            setKeyProfile(keyProfile.filter((k) => !(k.index === key && k.color === color)));
        } else {
            setKeyProfile([...keyProfile, { index: key, color }]);
        }
    };
    if (currentProfile) {
        return (
            <Box width={'100%'} display="flex" flexDirection="column" gap="32px" maxWidth={'1200px'} padding={'20px 32px'} margin={'0 auto'} alignItems={{ xs: 'center', sm: 'start' }}>
                <Typography variant="h4" sx={{ fontWeight: '400', pb: '16px' }}>
                    {intl.formatMessage({ id: 'platform.profile.header.title' })}
                </Typography>
                <Box maxWidth="440px" width={'100%'} display="flex" flexDirection="column" gap="8px">

                    {/* Select Account */}
                    <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px' }}>
                        {intl.formatMessage({ id: 'platform.profile.select.label' })}
                    </Typography>
                    <Box position="relative" display="flex" flexDirection="column" pb="24px">
                        <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" onClick={profileMenuClick} justifyContent="space-between" padding="16px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                            <Box display="flex" gap="8px" alignItems="center">
                                {
                                    currentProfile.data && currentProfile.data.profileImg ?
                                        (<img src={`${currentProfile.data.profileImg}`} height='32px' width='32px' style={{ borderRadius: '100%' }} />) :
                                        <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                                }

                                <Box display="flex" flexDirection="column">
                                    <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                        {currentProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                                    </Typography>
                                    <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                        {currentProfile.keyID.substring(0, 2) + currentProfile.keyID.substring(2, 8).toUpperCase() + '...' + currentProfile.keyID.substring(currentProfile.keyID.length - 6, currentProfile.keyID.length).toUpperCase()}
                                    </Typography>
                                </Box>
                            </Box>
                            {
                                !!currentProfile?.data?.tags && (
                                    <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                        <CircleIcon style={{ color: currentProfile.data.tags }} />
                                        <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                                    </Box>
                                )
                            }
                        </Box>
                        {isProfileDropdownOpen ? (
                            <ClickAwayListener onClickAway={() => setProfileDropdownOpen(false)}>
                                <Box maxWidth="440px" padding={'16px'} width={'100%'} position="absolute" top="0" display="flex" gap="8px" zIndex={9999} flexDirection="column" borderRadius="16px" boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)" bgcolor={theme === "Light" ? "#ffffff" : "#111113"} border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"}>
                                    <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" onClick={profileMenuClick} justifyContent="space-between" padding="16px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                                        <Box display="flex" gap="8px" alignItems="center">
                                            {
                                                currentProfile.data && currentProfile.data.profileImg ?
                                                    (<img src={`${currentProfile.data.profileImg}`} alt="Imagem Codificada em Base64" height='40px' width='40px' style={{ borderRadius: '100%' }} />) :
                                                    <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                                            }
                                            <Box display="flex" flexDirection="column">
                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                    {currentProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                                                </Typography>
                                                <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                                    {currentProfile.keyID.substring(0, 2) + currentProfile.keyID.substring(2, 8).toUpperCase() + '...' + currentProfile.keyID.substring(currentProfile.keyID.length - 6, currentProfile.keyID.length).toUpperCase()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                            <CircleIcon style={{ color: currentProfile.data && currentProfile.data.tags ? currentProfile.data.tags : '#FFAF00' }} />
                                            <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                                        </Box>
                                    </Box>
                                    <Box id='search-collapse' bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"} gap="8px" px="8px" borderRadius="50px" height="40px" width={isSearch ? '100%' : '40px'} display="flex" alignItems="center">
                                        <SvgIcon component={SearchIcon} onClick={() => setIsSearch(!isSearch)} />
                                        <SearchInput onChange={(e) => setFilter(e.target.value)} style={{ display: isSearch ? 'flex' : 'none', backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59" }} placeholder={intl.formatMessage({ id: 'platform.profile.searchplaceholder', defaultMessage: 'Search here...' })} />
                                    </Box>
                                    <Stack flexDirection={'row'} p={'0px 16px'}>
                                        <Typography sx={{ fontWeight: '500', fontSize: '14px', mr: '16px' }}>Tags</Typography>
                                        {tagColors.map((color, key) => (
                                            <Stack
                                                gap="8px"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{ cursor: 'pointer' }}
                                                key={key}
                                                onClick={() => handleIconClick(key, color)}
                                            >
                                                {keyProfile.some((selectedTag) => selectedTag.color === color) ? (
                                                    <CheckCircleIcon style={{ color }} />
                                                ) : (
                                                    <CircleIcon style={{ color }} />
                                                )}
                                            </Stack>
                                        ))}
                                    </Stack>
                                    <Box id='scrollable-content' maxHeight="440px" borderTop={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} borderBottom={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} display="flex" flexDirection="column" overflow="auto" p={'16px'}>

                                        {!filteredWallets ? (
                                            <Box display="flex" flexDirection="column">
                                                <Skeleton width={'100%'} height={'230px'} />
                                                <Skeleton width={'100%'} height={'230px'} />
                                                <Skeleton width={'100%'} height={'230px'} />
                                            </Box>) : filteredWallets.map((wallet: any, key: number) => (
                                                <Box key={key} flexShrink={0} style={{ cursor: "pointer" }} height="68px" display="flex" alignItems="center" onClick={() => { setCurrentProfile(wallet); store.dispatch(setActiveProfile(wallet)) }} justifyContent="space-between">
                                                    <Box display="flex" gap="8px" alignItems="center" >
                                                        {
                                                            wallet.data && wallet.data.profileImg ?
                                                                (<img src={`${wallet.data.profileImg}`} alt="Imagem Codificada em Base64" height='40px' width='40px' style={{ borderRadius: '100%' }} />) :
                                                                <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                                                        }
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                                {wallet.data && wallet.data.nickname ? wallet.data.nickname : `Account ${key}`}
                                                            </Typography>
                                                            <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                                                {wallet.keyID.substring(0, 2) + wallet.keyID.substring(2, 8).toUpperCase() + '...' + wallet.keyID.substring(wallet.keyID.length - 6, wallet.keyID.length).toUpperCase()}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {
                                                        !!wallet?.data?.tags && (
                                                            <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                                                <CircleIcon style={{ color: wallet.data?.tags }} />

                                                                <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            ))}

                                    </Box>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" padding="16px" gap="8px" style={{ cursor: 'pointer' }} color="#577DFF">
                                            <SvgIcon component={ControlPointIcon} inheritViewBox sx={{ fontSize: 24 }} />
                                            <Typography fontSize={'16px'} fontWeight={'700'} onClick={() => { setShowNewWalletModal(!showNewWalletModal); setShowKeyModal(false) }}>
                                                {intl.formatMessage({ id: 'platform.profile.createwallet', defaultMessage: 'Create another wallet' })}
                                            </Typography>
                                        </Box>
                                        {showNewWalletModal && (
                                            <Box
                                                width={['90%', '440px']}
                                                position='absolute'
                                                bottom='10vh'
                                                left='5vw'
                                            >
                                                <NewWalletModal
                                                    refresh={refresh}
                                                    setRefresh={setRefresh}
                                                    setShowModal={setShowNewWalletModal}
                                                />
                                            </Box>

                                        )}
                                        <Box display="flex" padding="16px" gap="8px" style={{ cursor: 'pointer' }} color="#577DFF">
                                            <SvgIcon component={AccountBalanceWalletOutlinedIcon} inheritViewBox sx={{ fontSize: 24 }} />

                                            <Typography fontSize={'16px'} fontWeight={'700'} onClick={() => { setShowKeyModal(!showKeyModal); setShowNewWalletModal(false) }}>
                                                {intl.formatMessage({ id: 'platform.profile.transferwallet', defaultMessage: 'Import wallet' })}
                                            </Typography>
                                        </Box>
                                        {showKeyModal && (
                                            <Box
                                                width={['90%', '440px']}
                                                position='absolute'
                                                bottom='5vh'
                                                left='5vw'
                                                p='1rem'
                                                bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
                                                boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
                                                border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"}
                                                borderRadius="16px"
                                                gap='8px'
                                            >
                                                <Box display='flex' justifyContent='space-between' width={'100%'}>
                                                    <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '16px', width: 'fit-content' }}>Import Wallet</Typography>
                                                    <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? CloseIconBlack : CloseIcon} inheritViewBox onClick={() => setShowKeyModal(false)} />
                                                </Box>

                                                <Typography style={{ fontWeight: '400', fontSize: '14px', width: 'fit-content', marginTop: '16px' }}>Insert Private Key</Typography>
                                                <InputStyle style={{ borderRadius: '8px', padding: '4px' }}>
                                                    <InputBase
                                                        sx={{ width: '100%', flex: 1, fontSize: '1rem', marginTop: '8px', borderRadius: '8px' }}
                                                        id="outlined-password-input"
                                                        type={'text'}
                                                        placeholder="Wallet private key"
                                                        value={privateKey}
                                                        onChange={(e) => setPrivateKey(e.currentTarget.value)}
                                                    />
                                                </InputStyle>
                                                <ContainerButton onClick={importOneWallet}>
                                                    <ButtonLabel>{!isLoading ? 'Import Wallet' : <CircularProgress size={24} color="inherit" />}</ButtonLabel>
                                                </ContainerButton>
                                            </Box>

                                        )}
                                    </Box>
                                </Box>
                            </ClickAwayListener>
                        ) : null}
                    </Box>

                </Box>
                <Box display="flex" gap="40px" width={'100%'} flexDirection={{ xs: 'column', lg: 'row' }} alignItems={{ xs: 'center', sm: 'start' }} >
                    <Box maxWidth="440px" width={'100%'} display="flex" flexDirection="column" gap="8px" >
                        <InfoCard currentProfile={currentProfile} />
                        <SettingsCard />
                    </Box>
                    <Box maxWidth="440px" width={'100%'} display="flex" flexDirection="column">
                        <Box style={{ width: '100%', height: '248px' }} display="flex" justifyContent="space-between" padding="16px" borderRadius="16px" border={'1px solid #E4E2E4'} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "white" : "#262527"} flexDirection={'column'}>
                            <Box bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"} padding={'4px 16px'} borderRadius={!coinDropDownOpen ? '32px' : "12px"} minWidth={'155px'} width={'fit-content'}>
                                {!coinDropDownOpen ? (
                                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                        <Box>
                                            <Box display="flex" flexDirection="column" width={'100%'}>
                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                    {selectedCoin}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                            <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} onClick={() => setCoinDropDownOpen(true)} style={{ cursor: 'pointer' }} />
                                        </Box>
                                    </Box>
                                ) : (
                                    <ClickAwayListener onClickAway={() => setCoinDropDownOpen(false)}>
                                        <Box >
                                            <Box display="flex" flexDirection="column" width={'100%'} onClick={() => selectShowedCoin('CNTP')}>
                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} style={{ cursor: 'pointer' }} sx={{
                                                    '&:hover': {
                                                        color: '#8DA8FF'
                                                    }
                                                }}>
                                                    CNTP
                                                </Typography>
                                            </Box>
                                            <Box display="flex" flexDirection="column" width={'100%'} onClick={() => selectShowedCoin('CONET Holesky')}>
                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} style={{ cursor: 'pointer' }} sx={{
                                                    '&:hover': {
                                                        color: '#8DA8FF'
                                                    }
                                                }}>
                                                    CONET Holesky
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ClickAwayListener>)}
                            </Box>


                            <Box style={{ position: 'relative' }}>
                                {(Number(cntp) < 0.01) &&
                                    (
                                        <div>
                                            <SvgIcon component={ReportProblemIcon} color="warning" fontSize="large" style={{ position: 'absolute', top: '0', right: '0' }} />
                                        </div>
                                    )
                                }

                                <SvgIcon component={CONETIcon} fontSize='small' inheritViewBox />
                                <Typography variant="subtitle1" fontSize={'32px'} fontWeight={'500'}>
                                    {Number(cntp)} {selectedCoin === 'CONET Holesky' ? 'CONET' : 'CNTP'}
                                </Typography>

                                {selectedCoin === 'CONET Holesky' ? (
                                    <Box display="flex" color="#8DA8FF" gap="8px" alignItems="center">
                                        <Button variant="contained" color="primary" style={{ padding: '8px', borderRadius: '16px', fontWeight: '900', height: '40px', width: '80px' }} onClick={() => generateFaucet(currentProfile.publicKeyArmor)}>
                                            {!isFaucetLoading ? 'FAUCET' : <CircularProgress size={24} color="inherit" />}
                                        </Button>
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>

                        {/* Send */}
                        <Box style={{
                            margin: "12px 0",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <img src={toSendIcon}></img>
                            <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'300'} color={'#929092'}>
                                Send
                            </Typography>
                        </Box>


                        <Box position="relative" display="flex" flexDirection="column" pb={{ xs: '96px', sm: "24px" }}>
                            <Box style={{ cursor: "pointer", width: '100%' }} display="flex" alignItems="center" onClick={transactionMenuClick} justifyContent="space-between" padding="16px" borderRadius="16px" border={'1px solid #E4E2E4'} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "white" : "#262527"}>
                                <Box display="flex" gap="8px" alignItems="center">
                                    <Box display="flex" flexDirection="column" width={'100%'}>
                                        <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                            {intl.formatMessage({ id: 'platform.TransactionDropdown.transactionHistoryLabel' })}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                    <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                                </Box>
                            </Box>
                            {isTransactionHistoryOpen ? (
                                <ClickAwayListener onClickAway={transacionCloseDropdown}>
                                    <Box width="440px" position="relative" top="0vh" display="flex" padding="16px" gap="8px" zIndex={9999} flexDirection="column" borderRadius="16px" border={'1px solid #E4E2E4'} bgcolor={theme === "Light" ? "#ffffff" : "#111113"}>
                                        <Box style={{ cursor: "pointer", width: '100%' }} display="flex" alignItems="center" onClick={transactionMenuClick} justifyContent="space-between" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "white" : "#363E59"}>
                                            <Box display="flex" gap="8px" alignItems="center">
                                                <Box display="flex" flexDirection="column" width={'100%'}>
                                                    <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                        {intl.formatMessage({ id: 'platform.TransactionDropdown.transactionHistoryLabel' })}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                                <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                                            </Box>
                                        </Box>
                                        <Box borderTop={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} display="flex" flexGrow={1} flexDirection="column" overflow="auto">
                                            {/* {transactionHistory.map((transaction, key) => (
                                                <Box key={key} flexShrink={0} style={{ cursor: "pointer" }} height="68px" display="flex" alignItems="center" onClick={onSelectProfile} justifyContent="space-between">
                                                    <Box display="flex" gap="8px" alignItems="center">
                                                        {transaction.action == "Send" ?
                                                            <SvgIcon component={sendIcon} inheritViewBox sx={{ fontSize: 32 }} />
                                                            :
                                                            <SvgIcon component={receiveIcon} inheritViewBox sx={{ fontSize: 32 }} />
                                                        }
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                                {transaction.action}
                                                            </Typography>
                                                            <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                                                {transaction.actionWallet}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box display="flex" gap="8px" alignItems="center" justifyContent="center">

                                                        {transaction.action == "Send" ?
                                                            <Typography fontSize={'16px'} fontWeight={'500'}>
                                                                {"- " + transaction.amount + " CNTP"}
                                                            </Typography>
                                                            :
                                                            <Typography fontSize={'16px'} fontWeight={'500'}>
                                                                {"+ " + transaction.amount + " CNTP"}
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </Box>
                                            ))} */}
                                        </Box>
                                    </Box>
                                </ClickAwayListener>
                            ) : null}
                        </Box>
                    </Box>

                </Box>
                <hr style={{ maxWidth: '920px', width: '100%', display: 'flex', justifyContent: 'center' }} />
                <Typography fontWeight={700} fontSize={'32px'}>Your Assets</Typography>
                <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} gap='24px' marginBottom={'100px'} alignItems={'center'}>
                    {!purchase ? (
                        <Box borderRadius="16px" display="flex" flexDirection="column" gap="16px" border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} bgcolor={localStorage.getItem('mui-mode') === 'light' ? 'white' : '#262527'} padding="24px" width={{ xs: '100%', sm: '440px' }}>
                            <Typography fontWeight={400} fontSize={'14px'} color={'#929092'}>Subscrition Certificate</Typography>
                            <Typography fontWeight={400} fontSize={'32px'} >0</Typography>
                            <Typography fontWeight={400} fontSize={'14px'} color={'#929092'}>0 USDT</Typography>
                            <PurchaseButtom
                                style={{
                                    width: '100%',
                                    textAlign: "center",
                                    maxWidth: '300px',
                                    margin: '0 auto',
                                    marginTop: '32px',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    padding: "0.65rem",
                                    borderRadius: "0.75rem",
                                    cursor: "pointer",
                                }}
                                onClick={() => setPurchase(true)}
                            >Buy Guardian Plan</PurchaseButtom>
                        </Box>
                    ) : (
                        <>
                            <PurchaseModal certificateNumber={certificateNumber} setCertificateNumber={setCertificateNumber} selectedCertificateCoin={selectedCertificateCoin} setSelectedCertificateCoin={setSelectedCertificateCoin} />
                        </>

                    )
                    }
                    <Box borderRadius="16px" display="flex" flexDirection="row" justifyContent={'space-between'} height='72px' gap="16px" border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} bgcolor={localStorage.getItem('mui-mode') === 'light' ? 'white' : '#262527'} padding="24px" width={{ xs: '100%', sm: '440px' }}>
                        <Box display="flex" flexDirection="row" justifyContent={'center'} alignItems={'center'} gap='8px'>
                            <Typography fontWeight={400} fontSize={'16px'}>Subscription Certificate</Typography>
                            <Typography fontWeight={700} fontSize={'16px'} >0</Typography>
                        </Box>
                        <Box>
                            <Typography fontSize="14px"
                                color="#8DA8FF"
                                fontWeight={700}>BUY MORE</Typography>
                        </Box>

                    </Box>
                </Box>
            </Box >
        )
    } else {
        return null;
    }
}

export default ProfileTest;
