import React, { useEffect, useState } from 'react';
import { styled as materialStyled, Avatar, IconButton, Input, Button, Select, MenuItem, FormControl, InputLabel, Paper, Card, TextField, Typography, Box, SvgIcon, ClickAwayListener, Stack, InputBase, PaperTypeMap } from '@mui/material';
import defaultProfilePicture from '../assets/default_profile.svg'
import ContentCopy from '@mui/icons-material/ContentCopy';
import { FormattedMessage, useIntl } from 'react-intl';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IProfile } from '../interface/profile.interface';
import { useColorScheme } from '@mui/material-next/styles';
import { ReactComponent as ConetAvatar } from "../../../../../assets/images/con-avatar.svg";
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckIcon from '@mui/icons-material/Check';
import { platform } from '../../../../../API/platform';
import store, { useTypedSelector } from '../../../../../store/store';
import { profile } from '../../../../../API';

import { setActiveProfile } from '../../../../../store/appState/appStateActions';
import { Console } from 'console';
import { toast } from 'react-toastify';
import { ButtonLabel, ContainerButton } from '../../dashboard/styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import CircularProgress from '@mui/material/CircularProgress';
import { forEach } from 'async';

interface IInfoCard {
  currentProfile: any;
}

const InputStyle = materialStyled(Paper)(() => ({
  display: 'flex',
  textAlign: 'center',
  marginBottom: '16px',
  borderRadius: '1rem',
  border: '1px solid #E4E2E4',

  width: '100%',
  height: '52px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  justifyContent: 'center'
}))

const InfoCard = (props: IInfoCard) => {
  const intl = useIntl();
  const { mode, setMode } = useColorScheme();
  const [showPrivateKeyModal, setShowPrivateKeyModal] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [keyID, setKeyId] = useState<string>();
  const [shortId, setShortId] = useState<string>();
  const [inputText, setInputText] = useState<string>();
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isMoreOpen, setMoreOpen] = useState(false);
  const [isTagOpen, setTagOpen] = useState(false);
  const [tagColor, setTagColor] = useState<string>();
  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const [profileImage, setProfileImage] = useState('')
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)
  const conet_platform = new platform(setConetPlatform, setWorkerLoading)
  const[profileVer, setProfileVer] = useState<number>(0)


  const authKey = useTypedSelector(state => state.appState.pass)
  useEffect(() => {
    const shortID = props.currentProfile.keyID.substring(0, 2) + props.currentProfile.keyID.substring(2, 8).toUpperCase() + '...' + props.currentProfile.keyID.substring(props.currentProfile.keyID.length - 6, props.currentProfile.keyID.length).toUpperCase()

    setKeyId(props.currentProfile.keyID);
    setInputText(props.currentProfile?.data ? props.currentProfile?.data?.nickname : intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' }))
    setShortId(shortID);
    setTagColor(props.currentProfile?.data?.tags);
    setProfileImage(props.currentProfile?.data?.profileImg ? props.currentProfile.data.profileImg : '')
  }, [props.currentProfile]);

  useEffect(() => {
    console.log('aqui', profileVer)
    // if(profileVer > 0){
    //   conet_platform.getAllProfiles()
    // }
  }, [profileVer])



  function convertImageToBase64(fileInput: HTMLInputElement) {

    const file = fileInput.files?.[0];

    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        alert("Please select a valid image file (PNG, JPG, JPEG).");
        return;
      }

      const reader = new FileReader();
      reader.onload = function () {
        const base64String = reader.result as string;
        setProfileImage(base64String)
      };
      reader.readAsDataURL(file);
    }
  }

  const removeImg = () => {
    setProfileImage('')
  }

  const handlePictureUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/png, image/jpeg, image/jpg";
    fileInput.addEventListener("change", () => { convertImageToBase64(fileInput); setProfileOpen(false) });
    fileInput.click();
  }

  const handleCopyToClipboard = () => {
    if (keyID) {
      navigator.clipboard.writeText(keyID);
      toast.success('Wallet copied to the clipboard', { position: "bottom-center", autoClose: 1500 },)
    } else {
      toast.error('Unable to get the Wallet id', { position: "bottom-center", autoClose: 1500 })
    }
  };

  const handleCopyToClipboardPrivate = async () => {
    if (password.length > 5) {
      const res = await conet_platform.testPasscode(password, setProfileVer)
      console.log('RES', res)

      if (res[0] === true) {
        navigator.clipboard.writeText(props.currentProfile?.privateKeyArmor);
        toast.success('Private Key copied to the clipboard', { position: "bottom-center", autoClose: 1500 })
      } else {
        toast.error('Unable to get the private key', { position: "bottom-center", autoClose: 1500 })
      }
    }
  };
  const user = props.currentProfile

  const handleSend = async () => {

    setSaveIsLoading(true)

    const newProfile: profile = {
      nickname: inputText || 'User',
      tags: tagColor,
      isPrimary: true,
      alias: 'current user',
      bio: '',
      profileImg: profileImage
    }

    const newData = { ...user, data: newProfile }
    const res_update = await conet_platform.updateProfile(authKey, newData)

    setSaveIsLoading(false)

    if (res_update.length > 0) {
      toast.success('Profile updated', { position: 'bottom-center', autoClose: 1500 })
    } else {
      toast.error('An error occurred', { position: 'bottom-center', autoClose: 1500 })
    }

    const res_getall = await conet_platform.getAllProfiles(authKey)

    for (let i = 0; i < res_getall.length; i++) {
      console.log(i, "=>", res_getall[i]?.keyID)

      if (res_getall[i]?.keyID && newData.keyID == res_getall[i]?.keyID) {
        store.dispatch(setActiveProfile(res_getall[i]));
      }
    }
    // store.dispatch(setActiveProfile(res_getall[0]));
  };

  const selectColorTag = (color: string) => {
    setTagColor(color);
    setTagOpen(false)
  }

  const tagColors = [
    "#FF5E5A", "#FFAF00", "#FFDE00", "#00E361", "#009CFF", "#D873FB", "#ACACB0"
  ]

  return (
    <Box borderRadius="16px" display="flex" flexDirection="column" gap="16px" border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} bgcolor={localStorage.getItem('mui-mode') === 'light' ? 'white' : '#262527'} padding="24px">
      {saveIsLoading &&
        // {/* {true && */}
        <Box style={{ background: 'black', width: '100%', height: '100vh', zIndex: '99', position: 'absolute', top: 0, left: 0, opacity: '0.6', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress style={{ color: 'white', height: 'fit-content', width: '10%' }} />
        </Box>
      }
      <Box position="relative" display="flex" justifyContent="space-between">
        <Box display="flex" position="relative" style={{ cursor: 'pointer' }} onClick={() => setProfileOpen((prev) => !prev)}>
          {profileImage ? (
            <>
              <img src={profileImage} alt="Profile" style={{ width: '72px', height: '72px', borderRadius: "100%" }} />
              <Box position="absolute" bottom={0} right={0} width="24px" height="24px" display="flex" alignItems="center" justifyContent="center" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"} borderRadius="100%">
                <SvgIcon component={EditIcon} inheritViewBox sx={{ fontSize: 16 }} />
              </Box>
            </>

          ) : (
            <>
              <SvgIcon component={ConetAvatar} inheritViewBox sx={{ fontSize: 72 }} />
              <Box position="absolute" bottom={0} right={0} width="24px" height="24px" display="flex" alignItems="center" justifyContent="center" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"} borderRadius="100%">
                <SvgIcon component={EditIcon} inheritViewBox sx={{ fontSize: 16 }} />
              </Box>
            </>
          )}
        </Box>
        {isProfileOpen ? (
          <ClickAwayListener onClickAway={() => setProfileOpen(false)}>
            <Box
              width={["150%", "310px"]}
              //height="359px"
              position="absolute"
              top="0vh"
              left={["-15vw", "80px"]}
              zIndex={9999}
              bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
              display="flex"
              flexDirection="column"
              borderRadius="16px"
              boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
              p="1rem"
              gap="32px"
            >
              <Stack justifyContent="space-between" direction="row">
                <Typography fontWeight="bold">
                  <FormattedMessage
                    id="platform.profile.infoCard.modal.profile"
                    defaultMessage="Profile Picture"
                  />
                </Typography>
                <SvgIcon
                  cursor="pointer"
                  onClick={() => setProfileOpen(false)}
                  component={CloseIcon}
                  inheritViewBox
                  sx={{ fontSize: 16 }}
                />
              </Stack>
              <Box display="flex" justifyContent="center" alignItems="center">
                {profileImage ? (
                  <img src={profileImage} alt="Profile" style={{ width: '72px', height: '72px', borderRadius: "100%" }} />
                ) : (
                  <>
                    <SvgIcon component={ConetAvatar} inheritViewBox sx={{ fontSize: 72 }} />
                  </>
                )}
              </Box>
              <Stack justifyContent="space-evenly" direction="row">
                <Button variant="contained" onClick={handlePictureUpload} style={{ backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#8DA8FF", borderRadius: "16px", height: "52px", padding: "16px 32px 16px 32px" }}>
                  <Typography fontWeight="bold" color={localStorage.getItem('mui-mode') === 'light' ? "white" : "#262527"} fontSize="16px">
                    <FormattedMessage id="platform.profile.infoCard.modal.profileupload" defaultMessage="Upload" />
                  </Typography>
                </Button>
                <Button variant="text" style={{ borderRadius: "16px", height: "52px", padding: "16px, 32px, 16px, 32px" }} onClick={() => removeImg()}>
                  <Typography fontWeight="bold" color={localStorage.getItem('mui-mode') === 'light' ? "red" : "#FFB4AB"} fontSize="16px">
                    <FormattedMessage id="platform.profile.infoCard.modal.profilecancel" defaultMessage="Remove" />
                  </Typography>
                </Button>
              </Stack>
            </Box>
          </ClickAwayListener>
        ) : null}

        <Box display="flex" position="relative" style={{ cursor: 'pointer' }} onClick={() => setMoreOpen((prev) => !prev)}>
          <MoreVertIcon />
        </Box>
        {isMoreOpen ? (
          <ClickAwayListener onClickAway={() => { setMoreOpen(false); setShowPrivateKeyModal(false) }}>
            <Box
              width="296px"
              //height="359px"
              position="absolute"
              top="0px"
              left={['0', "390px"]}
              zIndex={9999}
              bgcolor={mode === "light" ? "#ffffff" : "#111113"}
              display="flex"
              flexDirection="column"
              borderRadius="16px"
              boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
              p="1rem"
            >
              <Box height="56px" onClick={() => setShowPrivateKeyModal(true)} display="flex" alignItems="center" color={localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#8DA8FF"} style={{ cursor: 'pointer' }} padding="16px" gap="8px">
                <SvgIcon component={ContentCopyIcon} inheritViewBox sx={{ fontSize: 16 }} style={{ cursor: "pointer" }} />
                <Typography fontSize="16px" fontWeight={500} >
                  <FormattedMessage id="platform.profile.infoCard.modal.CopyPrivateKey" defaultMessage="Copy Private Key" />
                </Typography>
                {showPrivateKeyModal && (
                  <Box
                    width={['90%', '340px']}
                    position='absolute'
                    bottom='5vh'
                    left='5vw'
                    p='1rem'
                    bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
                    boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
                    border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"}
                    borderRadius="16px"
                    gap='8px'
                  >
                    <Box display='flex' justifyContent='space-between' width={'100%'}>
                      <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '16px', width: 'fit-content' }}>Insert your password</Typography>
                    </Box>

                    <Typography style={{ fontWeight: '400', fontSize: '14px', width: 'fit-content', marginTop: '16px' }}>Insert your password</Typography>
                    <InputStyle style={{ borderRadius: '8px', padding: '4px' }}>
                      <InputBase
                        sx={{ width: '100%', flex: 1, fontSize: '1rem', marginTop: '8px', borderRadius: '8px' }}
                        id="outlined-password-input"
                        type={'password'}
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                      />
                    </InputStyle>
                    <ContainerButton onClick={() => { { handleCopyToClipboardPrivate(); setShowPrivateKeyModal(false) } }}>
                      <ButtonLabel>Copy private key</ButtonLabel>
                    </ContainerButton>
                  </Box>

                )}
              </Box>
              <Box height="56px" display="flex" alignItems="center" color={localStorage.getItem('mui-mode') === 'light' ? "red" : "#FFB4AB"} style={{ cursor: 'pointer' }} padding="16px" gap="8px">
                <SvgIcon component={VisibilityOffIcon} inheritViewBox sx={{ fontSize: 16 }} style={{ cursor: "pointer" }} />
                <Typography fontSize="16px" fontWeight="bold" >
                  <FormattedMessage id="platform.profile.infoCard.modal.hideWallet" defaultMessage="Hide this wallet" />
                </Typography>
              </Box>
            </Box>
          </ClickAwayListener>
        ) : null}
      </Box>
      <Box display="flex" color="#8DA8FF" gap="8px" alignItems="center" style={{ cursor: 'pointer' }} onClick={handleCopyToClipboard}>
        <Typography fontSize="14px" fontWeight={500} >{shortId}</Typography>
        <SvgIcon component={ContentCopyIcon} inheritViewBox sx={{ fontSize: 16 }} />
      </Box>
      <Box display="flex" color="#8DA8FF" gap="8px" alignItems="center">
        <TextField id="outlined-read-only-input" value={inputText} fullWidth
          onChange={(e) => setInputText(e.target.value)} InputProps={{
            style: { fontSize: '16px', padding: '0px', borderRadius: '16px', maxWidth: '100%', maxHeight: '40px' }
          }} />
        <Button variant="contained" color="primary" disabled={saveIsLoading} onClick={() => handleSend()} style={{ padding: '8px 25px', borderRadius: '16px', fontWeight: '900', height: '40px', width: 'auto' }} >
          {saveIsLoading ? <CircularProgress style={{ height: '20px', width: '20px' }} /> : intl.formatMessage({ id: 'platform.profile.infoCard.saveButton' })}
        </Button>
      </Box>
      <Box display="flex" alignItems="center" position="relative">

        <Box width="139px" height="40px" display="flex" alignItems="center" justifyContent="space-between" padding="8px 16px 8px 16px" borderRadius="16px" border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} style={{ cursor: "pointer" }} onClick={() => setTagOpen((prev) => !prev)}>
          <Box display="flex" alignItems="center" gap="8px">
            <Typography variant="body2" fontSize="16px">
              Tag
            </Typography>
            <Box width="16px" height="16px" borderRadius="100%" bgcolor={tagColor} />
          </Box>
          <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
        </Box>
        {isTagOpen ? (
          <ClickAwayListener onClickAway={() => setTagOpen(false)}>
            <Box
              width="280px"
              //height="359px"
              position="absolute"
              top="48px"
              left={0}
              zIndex={9999}
              bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
              display="flex"
              flexDirection="column"
              borderRadius="16px"
              boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
              p="1rem"
              gap="32px"
            >
              <Stack justifyContent="space-between" direction="row">
                <Typography fontWeight="bold">
                  <FormattedMessage
                    id="platform.profile.infoCard.modal.profiletag"
                    defaultMessage="Tag your account"
                  />
                </Typography>
                <SvgIcon
                  cursor="pointer"
                  onClick={() => setTagOpen(false)}
                  component={CloseIcon}
                  inheritViewBox
                  sx={{ fontSize: 16 }}
                />
              </Stack>
              <Box display="flex" alignItems="center" gap="8px">
                {tagColors.map((color, index) => (
                  <Box display="flex" alignItems="center" justifyContent="center" key={index} width="20px" height="20px" borderRadius="100%" bgcolor={color} style={{ cursor: 'pointer' }} onClick={() => selectColorTag(color)}>
                    {tagColor === color ? (
                      <SvgIcon component={CheckIcon} inheritViewBox sx={{ fontSize: 18 }} />
                    ) : null}
                  </Box>
                ))}
                <SvgIcon component={DoDisturbIcon} inheritViewBox sx={{ fontSize: 20 }} style={{ cursor: 'pointer' }} onClick={() => setTagColor('')} />
              </Box>
            </Box>
          </ClickAwayListener>
        ) : null}

      </Box>
    </Box>
  );
};

export default InfoCard;
