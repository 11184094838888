import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, SvgIcon, Stack, Box, Checkbox, Select, MenuItem, CircularProgress } from "@mui/material";
import { useTheme } from "styled-components";
import { loadCSS } from "fg-loadcss";
import {
  BannerIcon,
  CardNodeBannerFooter,
  PurchaseButtom,
  SuperNodeBannerBuyCard,
  SuperNodeBannerBuyCardBuying,
  SuperNodeBannerContent,
  SuperNodeBannerFooter,
  SuperNodeBannerWrapper,
} from "../styles/SuperNodeBanner";
import expansiveIcon from "../../../../../assets/icons/expansive.svg";
import worldIcon from "../../../../../assets/icons/world.svg";
import maintenanceIcon from "../../../../../assets/icons/maintenance.svg";
import gearIcon from "../../../../../assets/icons/gear.svg";
import { ReactComponent as TIcon } from "../../../../../assets/icons/t-icon.svg";
import { MdCheckCircleOutline } from "react-icons/md";
import img1 from "../../../../../assets/images/img1.png";
import img2 from "../../../../../assets/images/img2.png";
import img3 from "../../../../../assets/images/img3.png";
import img4 from "../../../../../assets/images/img4.png";
import img5 from "../assets/sucess-transaction-icon.png"
import img6 from "../assets/Blockchain-1.svg"

import blastImage from "../../../../../assets/images/blastImage.png";
import cloudImage from "../../../../../assets/images/cloudImage.png";
import leftArrow from "../assets/left-arrow-node-banner.png"


import certificate_icon from "../../../../../assets/images/certificate_icon.png";


import pay from "../../../../../assets/images/tether-usdt-seeklogo 1.png";
import coin from "../../../../../assets/images/usd-coin-usdc-logo 1.png";
import eth from "../../../../../assets/images/ethereum-eth 1.png";
import coin3 from "../../../../../assets/images/coin3.png";
import coin4 from "../../../../../assets/images/coin4.png";

import { ReactComponent as Lock } from "../../../../../assets/icons/lock.svg";

import { ReactComponent as minusIcon } from "../../../../../assets/icons/minusIcon.svg";
import { ReactComponent as plusIcon } from "../../../../../assets/icons/plusIcon.svg";

import { ReactComponent as minusIconBlack } from "../../../../../assets/icons/minus_icon_black.svg";
import { ReactComponent as plusIconBlack } from "../../../../../assets/icons/plus_icon_black.svg";

import { ReactComponent as arrowRightBlack } from "../../../../../assets/icons/arrowRightBlack.svg";
import { ReactComponent as arrowRightWhite } from "../../../../../assets/icons/arrowRightWhite.svg";


import { ReactComponent as ConetAvatar } from "../../../../../assets/images/con-avatar.svg";

import { Wheel } from 'react-custom-roulette'
import pointerRoullete from "../../../../../assets/images/pointerRoullete.png";


import { toast } from 'react-toastify'


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { platform } from "../../../../../API/platform";
import { Button } from "@mui/base";
import { useTypedSelector } from "../../../../../store/store";
import { useFetchPrices } from "../../../../../API/fetchPrices";
import { useFetchData } from "../../../../../API/refresh";
import ProfileTest from "../../profileTEST";
import { Become } from "./Become"

export const SuperNodeBanner: React.FC = (props: any) => {
  const intl = useIntl();
  const { colors } = useTheme().ui;
  const [isBuying, setIsBuying] = useState<boolean>(false)
  const [guardianQuant, setGuardianQuant] = useState<number>(1)
  const [totalPrice, setTotalPrice] = useState<number | undefined>()
  const theme = useTheme();
  const [multiplierCNTP, setMultiplierCNTP] = useState<number>(1)
  const [isAgreementSigned, setIsAgreementSigned] = useState<boolean>(false)
  const [purchaseAble, setPurchaseAble] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [wasAgreementRead, setWasAgreementRead] = useState<boolean>(false)
  const [isAgreementOpened, setIsAgreementOpen] = useState<boolean>(false)
  const [selectedCoin, setSelectedCoin] = useState<string>('usdt')
  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const conet_platform = new platform(setConetPlatform, setWorkerLoading)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const profile = useTypedSelector(state => state.appState.activeProfile)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  // Devider layout configuration
  const borderDevider = localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#111113"
  const circleDevider = localStorage.getItem('mui-mode') === 'light' ? "#f6f8ff" : "#303032"
  const colorDevider = localStorage.getItem('mui-mode') === 'light' ? "#49454f" : "#111113"

  const pointerProperties = {
    src: pointerRoullete,
    style: {
      top: 'calc(50% - 16px)',
      width: '37px',
      height: '31px',
      right: '-10px'
    }
  }

  const wheelData = [
    { option: 'WIN', style: { backgroundColor: "#79F8FF", textColor: 'black' }, optionSize: 1 },
    { option: 'LOSE', style: { backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#303032", textColor: 'white' }, optionSize: 4 },
    { option: 'WIN', style: { backgroundColor: "#79F8FF", textColor: 'black' }, optionSize: 1 },
    { option: 'LOSE', style: { backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#303032", textColor: 'white' }, optionSize: 4 },
    { option: 'WIN', style: { backgroundColor: "#79F8FF", textColor: 'black' }, optionSize: 1 },
    { option: 'LOSE', style: { backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#303032", textColor: 'white' }, optionSize: 4 },
  ]

  console.log('profile', profile)
  const handleSpinClick = () => {
    //console.log("ENTROU")
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * wheelData.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
      //console.log("RESULT", newPrizeNumber)
    }
  }

  const handleChange = (event: any) => {
    setSelectedCoin(event.target.value);
  };

  const prices = useFetchPrices()
  const data = useFetchData()

  const currentUrl = window.location.href;
  useEffect(() => {
    function verifyPurchase(url: string) {
      //const url = 'testv2.network.conet/purchase=true';
      const regex = /purchase=true/;
      const isPurchaseTrue = regex.test(url);
      setPurchaseAble(isPurchaseTrue)
    }
    verifyPurchase(currentUrl)
  }, [currentUrl])

  const confirmBuy = async () => {
    // const res = await conet_platform.getAssetsPrice()
    // const value = prices[selectedCoin.toUpperCase()].USD
    const value = 1000
    setTotalPrice(guardianQuant * value)
  }

  const confirmPrePurchase = async () => {
    setIsLoading(true)
    const res = await conet_platform.prePurchase(100, String(guardianQuant), profile, 'dusdt')
    console.log('RES ====>', res)
    setIsLoading(false)
    confirmBuy()
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!active) return;

      const node = loadCSS(
        "https://use.fontawesome.com/releases/v6.5.1/css/all.css",
        //@ts-ignore
        document.querySelector("#font-awesome-css") || document.head.firstChild
      );

      return () => {
        active = false;
        node.parentNode!.removeChild(node);
      };
    };

    let active = true;
    fetchData();
  }, []);


  const getRefferRate = (num: number) => {

    switch (num) {
      case 0: {
        return 0
      }
      case 1: {
        return 1
      }
      case 2: {
        return 2.2
      }
      case 3: {
        return 3.5
      }
      case 4: {
        return 4.85
      }
      default: {
        return ((num - 4) * 0.35 + 0.85 + num).toFixed(2)
      }
    }
  }
  useEffect(() => {
    const element = document.getElementById(`airdrop`);
    if (!element) return;

    element.innerHTML = element.innerHTML.replace(",", `<br />`);
    element.innerHTML = element.innerHTML.replace(";", `<br />`);
  }, []);
  const currentProfile = profile
  return (
    <SuperNodeBannerWrapper margin="auto">
      <Box display='flex' flexDirection={['column', 'column']} p={'16px'} gap='16px'>
        <Box style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
          {/*<span style={{ borderRadius: '50%', background: circleDevider, border: '5px solid black', borderColor: borderDevider, textAlign: "center", height: '50px', width: '50px', zIndex: '2', lineHeight: '40px', color: colorDevider }}>1</span>
          <span style={{ height: '25px', borderBottom: '1px solid #929092', maxWidth: '250px', width: '100%', position: 'absolute' }}></span>*/}
        </Box>
        <Box display='flex' justifyContent='space-between'>


          <SuperNodeBannerContent>
            <Box display='flex' width="100%" flexDirection={['column', 'column', 'row']} gap={[6, 12]} alignItems='center'>
              <Stack direction="column" flex="1" spacing={4} maxWidth={'430px'}>
                <Stack spacing={1}>

                  <Typography sx={{ textAlign: `left`, width: `281px`, fontSize: '36px', lineHeight: `3rem` }}>
                    Embracing <span style={{ color: ' #8DA8FF', fontWeight: 700 }}> Decentralization </span> for Everyone
                  </Typography>
                </Stack>
                <Stack direction="column" gap="0.5rem">

                  <Stack direction="row" alignItems="center" gap="0.5rem">
                    <img width={'25px'} src={pay} />
                    <img width={'25px'} src={coin} />
                    <img width={'25px'} src={eth} />
                    <img width={'25px'} src={coin3} />
                    <img width={'25px'} src={coin4} />
                  </Stack>

                  <Typography fontSize="14px" fontWeight="600">
                    Pay in USDT, ETH, USDB or BNB
                  </Typography>
                </Stack>

                <Stack>
                  <Typography fontSize="14px" fontWeight="600">
                    Your Available Balance
                  </Typography>



                  <Box style={{ marginTop: '8px', border: '1px solid #474648', padding: '24px', borderRadius: '16px' }} maxWidth={'360px'}>

                    <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>Binance Smart Chain</Typography>

                    <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                      <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                        <img src={pay} />
                        <Typography fontSize="14px" fontWeight="600">USDT</Typography>
                      </Box>
                      <Typography fontSize="14px" fontWeight="600">$ {profile.tokens?.usdt.balance}</Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                      <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                        <img width={'25px'} src={coin4} />
                        <Typography fontSize="14px" fontWeight="600">BNB</Typography>
                      </Box>
                      <Typography fontSize="14px" fontWeight="600"> $ {profile.tokens?.wbnb.balance}</Typography>
                    </Stack>


                    <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>ETH Network</Typography>
                    <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                      <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                        <img width={'25px'} src={eth} />
                        <Typography fontSize="14px" fontWeight="600">ETH</Typography>
                      </Box>
                      <Typography fontSize="14px" fontWeight="600">$ {profile.tokens?.eth.balance}</Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                      <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                        <img width={'25px'} src={pay} />
                        <Typography fontSize="14px" fontWeight="600">USDT</Typography>
                      </Box>
                      <Typography fontSize="14px" fontWeight="600">$ {profile.tokens?.wusdt.balance}</Typography>
                    </Stack>



                    <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>Blast Network</Typography>
                    <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                      <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                        <img width={'25px'} src={coin3} />
                        <Typography fontSize="14px" fontWeight="600">USDB</Typography>
                      </Box>
                      <Typography fontSize="14px" fontWeight="600">$ {profile.tokens?.usdb.balance}</Typography>
                    </Stack>


                    <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} ml={'8px'}>
                      <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                        <img width={'25px'} src={eth} />
                        <Typography fontSize="14px" fontWeight="600">ETH</Typography>
                      </Box>
                      <Typography fontSize="14px" fontWeight="600">$ {profile.tokens?.eth.balance}</Typography>
                    </Stack>

                  </Box>
                </Stack>

              </Stack>
              {/*<SuperNodeBannerBuyCardBuying
              style={{
                backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#1B1B1D",
                border: localStorage.getItem("mui-mode") === 'light' ? "1px solid #E4E2E4" : '1px solid #474648',
                width: '100%',
                maxWidth: '550px'
              }}>
              {(!isBuying && !totalPrice) &&
                (
                  <>
                    <Stack direction="column" spacing={4} color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}>
                      <Box>
                        <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                          Select Account
                        </Typography>
                        <Box position="relative" display="flex" flexDirection="column" pb="24px">
                          <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#262527"}>
                            <Box display="flex" gap="8px" alignItems="center">
                              <Box display="flex" flexDirection="column">
                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'700'}>
                                  Account 04
                                </Typography>
                                <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                  0x412BA4...03AB46
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                              <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box style={{ marginTop: '0' }}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                          <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                            Select Quantity
                          </Typography>
                        </Box>
                        <Box position="relative" display="flex" flexDirection="column" pb="24px">
                          <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#262527"}>
                            <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? minusIconBlack : minusIcon} inheritViewBox fontSize="large" onClick={() => { setMultiplierCNTP(getRefferRate(guardianQuant > 1 ? guardianQuant - 1 : 1)); guardianQuant > 1 ? setGuardianQuant(guardianQuant - 1) : null }} />
                            <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '24px' }}>
                              {guardianQuant}
                            </Typography>
                            <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? plusIconBlack : plusIcon} inheritViewBox fontSize="large" onClick={() => { setMultiplierCNTP(getRefferRate(guardianQuant < 99 ? guardianQuant + 1 : 99)); setGuardianQuant(guardianQuant < 99 ? guardianQuant + 1 : 99); }} />
                          </Box>
                        </Box>
                      </Box>
                    </Stack>

                    <Stack flex="1" direction="column" justifyContent="flex-end">
                      <PurchaseButtom
                        onClick={() => setIsBuying(true)}
                        style={{
                          width: '100%',
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",
                          background: '#585a5f',
                          color: '#979797',
                          height: '56px',
                          backgroundColor: circleDevider
                        }}
                        disabled={true}
                      >
                        Purchase Soon
                      </PurchaseButtom>

                      <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                        <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                        Secure payment
                      </Stack>
                    </Stack>
                  </>
                )}
              {(isBuying && totalPrice && !confirm) && (
                <Box>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0px' }}>
                    <img
                      src={leftArrow}
                      alt='back'
                      height={30}
                      width={30}
                      style={{ cursor: 'pointer' }}
                      onClick={() => { setIsBuying(false); setTotalPrice(undefined) }}
                    />
                    <Typography
                      fontSize="24px"
                      color="#8DA8FF"
                      fontWeight={'700'}
                    >
                      Confirm your order
                    </Typography>
                  </div>

                  <div>
                    <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Paying with</Typography>
                    <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="16px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                      <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '20px', color: '#fff' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {selectedCoin === 'usdt' && <img src={pay} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />}
                          {selectedCoin === 'bnb' && <img src={coin4} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px', height: '25px', width: '25px' }} />}
                          {selectedCoin === 'eth' && <img src={eth} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />}
                          <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                            {selectedCoin.toUpperCase()}
                          </Typography>
                        </div>
                      </Typography>

                    </Box>
                    <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Wallet</Typography>
                    {
                      data.wallet.length && (
                        <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                          <Box display="flex" gap="8px" alignItems="center">


                            {data.wallet[0]?.data && data.wallet[0]?.data.profileImg ? (
                              <img
                                src={` ${data.wallet[0]?.data.profileImg}`}
                                alt="Imagem Codificada em Base64"
                                height="20px"
                                width="20px"
                                style={{ borderRadius: "100%" }}
                              />
                            ) : (
                              <SvgIcon
                                component={ConetAvatar}
                                inheritViewBox
                                sx={{ fontSize: 32 }}
                              />
                            )}

                            <Box display="flex" flexDirection="column">
                              <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                                {currentProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                              </Typography>
                              <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                {currentProfile?.keyID.substring(0, 2) + currentProfile?.keyID.substring(2, 8).toUpperCase() + '...' + currentProfile?.keyID.substring(currentProfile?.keyID.length - 6, currentProfile?.keyID.length).toUpperCase()}
                              </Typography>
                            </Box>
                          </Box>

                        </Box>)
                    }
                    <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Summary</Typography>
                    <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                      <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '20px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography style={{ fontSize: '20px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{multiplierCNTP * guardianQuant * 1000}  </Typography>
                          {selectedCoin.toUpperCase()}
                        </div>
                      </Typography>

                    </Box>
                  </div>

                  <Typography style={{ color: '#fff', marginTop: '24px' }}>GAS fee: 0%</Typography>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '164px' }}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                      <Checkbox checked={isAgreementSigned} onChange={() => setIsAgreementSigned(!isAgreementSigned)}></Checkbox>
                      <Typography style={{ fontSize: '16px', marginTop: '5px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>I agree the <span style={{ textDecoration: 'underline' }}>agreements</span></Typography>
                    </Box>
                    <PurchaseButtom
                      style={{
                        width: '100%',
                        textAlign: "center",
                        maxWidth: '300px',
                        margin: '0 auto',
                        marginTop: '32px',
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "600",
                        fontSize: "15px",
                        padding: "0.65rem",
                        borderRadius: "0.75rem",
                        cursor: "pointer",
                      }}
                      disabled={!isAgreementSigned}
                      onClick={() => setConfirm(true)}
                    >
                      Pay {selectedCoin.toUpperCase()} ${multiplierCNTP * guardianQuant * 1000}
                    </PurchaseButtom>
                    <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                      <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                      Secure payment
                    </Stack>
                  </div>

                </Box>
              )}
              {(isBuying && totalPrice && confirm) && (
                <Box>
                  <Box>
                    <img src={img5} alt='success' width='32px' />
                    <Typography style={{ fontSize: '36px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>The transaction </Typography>
                    <p>
                      <span style={{ color: ' #8DA8FF', fontWeight: 700, fontSize: '36px' }}> was successful </span>
                    </p>
                  </Box>
                  <div>
                    <div style={{ display: 'flex', marginTop: '20px', fontSize: '24px' }}>
                      <Typography fontSize={'24px'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Fiat value </Typography>
                      <Typography fontSize={'24px'} style={{ marginLeft: '8px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}> {totalPrice} {selectedCoin.toUpperCase()}</Typography>
                    </div>
                    <Typography style={{ marginTop: '24px', marginBottom: '200px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}> Gas Fee: 0%</Typography>
                    <Box bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"} style={{ borderRadius: '16px', padding: '8px 16px', display: 'flex', gap: '24px' }}>
                      <img src={img6} width={72} />
                      <Typography fontSize="24px" fontWeight="400" lineHeight='32px' color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}><span style={{ fontWeight: 'bold' }}>Congratulations! </span>You now have <span style={{ color: ' #8DA8FF', fontWeight: 700 }}>100</span> guardian nodes.</Typography>
                    </Box>
                    <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography style={{ color: ' #8DA8FF', fontWeight: 700, cursor: 'pointer' }} onClick={() => <ProfileTest />}>
                        Go to profile
                      </Typography>
                      <PurchaseButtom
                        style={{
                          width: '100%',
                          textAlign: "center",
                          maxWidth: '300px',
                          margin: '8px auto',
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",
                        }}
                        onClick={() => { setIsBuying(false); setTotalPrice(undefined); setConfirm(false) }}
                      >
                        Make new one
                      </PurchaseButtom>
                      <Stack flexDirection={'row'} justifyContent={'center'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                        <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                        Secure payment
                      </Stack>
                    </div>

                  </div>

                </Box>
              )}
            </SuperNodeBannerBuyCardBuying>*/}
            </Box>
          </SuperNodeBannerContent >

          {/* GUARDIAN BANNER WEBSITE */}
          {/* <Box style={{display:'flex', justifyContent:'center', marginBottom:'32px'}}>
          <Become/>
        </Box> */}

          {/* ROULLETE WHEEL */}
          {/*<Box style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
          <span style={{ borderRadius: '50%', background: circleDevider, border: '5px solid black', borderColor: borderDevider, textAlign: "center", height: '50px', width: '50px', zIndex: '2', lineHeight: '40px', color: colorDevider }}>2</span>
          <span style={{ height: '25px', borderBottom: '1px solid #929092', maxWidth: '250px', width: '100%', position: 'absolute' }}></span>
        </Box> */}
          {/*         <Box>
          <Typography fontSize="36px" lineHeight='32px' fontWeight='800' textAlign={'center'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Spin the Wheel</Typography>
        </Box>*/}

          {/*         <Box style={{ display: 'flex', marginBottom: '32px', alignItems: 'center', justifyContent: 'space-around' }}>
          <Box style={{ display: 'flex' }} flexDirection={'column'} maxWidth={'320px'} alignItems={'center'}>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={wheelData}
              spinDuration={0.5}
              outerBorderColor={localStorage.getItem('mui-mode') === 'light' ? "#D6E3FF" : "#000"}
              outerBorderWidth={20}
              radiusLineWidth={0}
              fontSize={12}
              onStopSpinning={() => {
                setMustSpin(false);
              }}

              // pointerRoullete
              pointerProps={pointerProperties}
            />
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
              <img width={'14px'} height={'14px'} src={certificate_icon} />
              <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}> x 0 Subscription Certificates</Typography>
            </Box>

            <PurchaseButtom
              style={{
                width: '100%',
                height: '46px',
                textAlign: "center",
                maxWidth: '130px',
                margin: '0 auto',
                marginTop: '32px',
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "15px",
                padding: "0.65rem",
                borderRadius: "0.75rem",
                cursor: "pointer",
                backgroundColor: circleDevider
              }}
              disabled={true}
            //onClick={() => { console.log("click"); handleSpinClick() }}
            >
              SPIN
            </PurchaseButtom>
          </Box>
                  <Box style={{ border: '1px solid #474648', borderRadius: '8px', height: '350px', padding: '24px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Stack>
            <Typography style={{ fontSize: "36px", fontWeight: '800' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>You Won!</Typography>
            <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>You are now elegible to buy Guardian Plan.</Typography>
          </Stack>
          <Stack>
            <Typography style={{ fontSize: "14px", fontWeight: '800', marginBottom: '8px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>History</Typography>
            <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#929092" : "#929092"}>1x you lost</Typography>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#79F8FF" : "#79F8FF"}>1x you won</Typography>
              <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#929092" : "#929092"}>98498486498764989</Typography>
            </Box>
          </Stack>
        </Box> 
        </Box>*/}

          {/*         <Box style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
          <span style={{ borderRadius: '50%', background: circleDevider, border: '5px solid black', borderColor: borderDevider, textAlign: "center", height: '50px', width: '50px', zIndex: '2', lineHeight: '40px', color: colorDevider }}>3</span>
          <span style={{ height: '25px', borderBottom: '1px solid #929092', maxWidth: '250px', width: '100%', position: 'absolute' }}></span>
        </Box>*/}
          <SuperNodeBannerContent style={{
            width: '100%',
            maxWidth: "550px"
          }}>
            <Box display='flex' width="100%" flexDirection={['column', 'column', 'row']} gap={[6, 12]} alignItems='flex-start'>
              {/*  <Stack direction="column" flex="1" spacing={4} maxWidth={'430px'}>
              <Stack spacing={1}>

                <Typography sx={{ textAlign: `left`, width: `281px`, fontSize: '36px', lineHeight: `3rem` }}>
                  Embracing <span style={{ color: ' #8DA8FF', fontWeight: 700 }}> Decentralization </span> for Everyone
                </Typography>
              </Stack>
              <Stack direction="column" gap="0.5rem">

                <Stack direction="row" alignItems="center" gap="0.5rem">
                  <img width={'25px'} src={pay} />
                  <img width={'25px'} src={coin} />
                  <img width={'25px'} src={eth} />
                  <img width={'25px'} src={coin3} />
                  <img width={'25px'} src={coin4} />
                </Stack>

                <Typography fontSize="14px" fontWeight="600">
                  Pay in USDT, ETH, USDB or BNB
                </Typography>
              </Stack>
            </Stack>*/}
              <SuperNodeBannerBuyCardBuying
                style={{
                  backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#1B1B1D",
                  border: localStorage.getItem("mui-mode") === 'light' ? "1px solid #E4E2E4" : '1px solid #474648',
                  width: '100%',
                  maxWidth: '550px'
                }}>
                {(!isBuying && !totalPrice) &&
                  (
                    <>
                      <Stack direction="column" spacing={4} color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}>
                        <Stack>
                          <Typography
                            fontSize="24px"
                            color="#8DA8FF"
                            fontWeight={'700'}
                          >
                            Become a Guardian
                          </Typography>
                        </Stack>

                        <Box>
                          <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                            Select Account
                          </Typography>
                          <Box position="relative" display="flex" flexDirection="column" pb="24px">
                            <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                              <Box display="flex" gap="8px" alignItems="center">
                                <Box display="flex" flexDirection="column">
                                  <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'700'}>
                                    Account 04
                                  </Typography>
                                  <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                    0x412BA4...03AB46
                                  </Typography>
                                </Box>
                              </Box>
                              <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box>
                          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                            <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                              Select Quantity
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                              Receive {multiplierCNTP} x more CNTP
                            </Typography>

                          </Box>
                          <Box position="relative" display="flex" flexDirection="column" pb="24px">
                            <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                              <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? minusIconBlack : minusIcon} inheritViewBox fontSize="large" onClick={() => { setMultiplierCNTP(getRefferRate(guardianQuant > 1 ? guardianQuant - 1 : 1)); guardianQuant > 1 ? setGuardianQuant(guardianQuant - 1) : null }} />
                              <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '24px' }}>
                                {guardianQuant}
                              </Typography>
                              <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? plusIconBlack : plusIcon} inheritViewBox fontSize="large" onClick={() => { setMultiplierCNTP(getRefferRate(guardianQuant < 99 ? guardianQuant + 1 : 99)); setGuardianQuant(guardianQuant < 99 ? guardianQuant + 1 : 99); }} />
                            </Box>
                          </Box>
                        </Box>

                        <Stack alignItems="center" width={'100%'}>
                          <Typography width={'100%'} textAlign={'left'} sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>Total</Typography>
                          <Typography fontSize="57px">{formatter.format(guardianQuant * 1000)}</Typography>
                        </Stack>

                        <Box display={'flex'} alignItems={'center'} gap={2} margin={'0 auto'} justifyContent={'center'} mt={'16px'}>
                          <Select
                            id="coin-select"
                            defaultValue="usdt"
                            label='USDT'
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownIcon}
                            variant="standard"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <MenuItem value="usdt"><img src={pay} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />USDT</MenuItem>
                            <MenuItem value="bnb"><img src={coin4} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px', height: '25px', width: '25px' }} />BNB</MenuItem>
                            <MenuItem value="eth"><img src={eth} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />ETH</MenuItem>
                          </Select>
                        </Box>
                      </Stack>
                      <Box marginTop={'48px'} borderRadius='16px' padding='8px' display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'center'} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#111113"} style={{ cursor: 'pointer' }}>
                        <Typography textAlign='center' color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"} onClick={() => setIsAgreementOpen(!isAgreementOpened)}>Agreement</Typography>
                        {isAgreementOpened &&
                          <>
                            <Stack fontSize={'14px'} padding={'8px 24px'} gap={2} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                              <Typography fontWeight={700}>IGO (Initial Guardian Offering) AGREEMENT</Typography>
                              <Typography>This IGO Agreement (the "Agreement") is entered into as of Mar 20, 2024, by and CoNET Network Foundation (hereinafter referred to as the "Issuer"), and the undersigned public participant (hereinafter referred to as the "Participant").</Typography>
                              <Typography fontWeight={700}>1. DEFINITIONS</Typography>
                              <Typography>1.1 "IGO" refers to the Initial Guardian Offering conducted by the Issuer for the sale of its CoNET Guardian Plan.</Typography>
                              <Typography>1.2 "CNTP" and "CoNET Super Node" refer to the digital assets issued by the Issuer during the IGO.</Typography>
                              <Typography fontWeight={700}>2. PARTICIPATION</Typography>
                              <Typography>2.1 The Participant acknowledges and agrees to participate in the IGO and purchase Guardian Plan at the price specified in the IGO official CoNET Platform V2.0.</Typography>
                              <Typography>2.2 The Participant acknowledges that the participation in the IGO carries inherent risks, and the Participant is solely responsible for conducting its own research before participating.</Typography>
                              <Typography fontWeight={700}>3. Guardian Plan PRICE AND PAYMENT</Typography>
                              <Typography>3.1 The price per Guardian Plan during the IGO is set at USD$1,000 at the first batch (5,000), USD$1,350 at the second batch (5,000), USD$1,850 at the third batch (5,000), Total 15,000 Guardian Plans. Payables in USDT/USDC/USDB/BUSD.</Typography>
                              <Typography>3.2 During the IGO phase, Guardian Plans are available for presale to users worldwide, including all CoNET community members. Certain community users who purchase Guardian Plan on the official CoNET Platform are entitled to a one-year repurchase agreement.</Typography>
                              <Typography>3.3  The project team is committed to ensuring the investment security of purchasers of the "Guardian Plan" and promises a repurchase at cost.</Typography>
                              <Typography>After one-year successful IGO purchase, the Guardian Plan buyer can execute a repurchase agreement through a smart contract at 100% of the purchase price in USDT assets.</Typography>
                              <Typography>Repurchase Lock-up Period: Within one year from the date of purchase of the "Guardian Plan".</Typography>
                              <Typography>During the repurchase lock-up period, if the CoNET token ($CONET) is successfully listed on one of the exchanges such as Coinbase, Binance, OKX, or BitGet, Guardians can be given a 7-day cooling-off period to decide whether they need to be repurchased. If the cooling-off period has passed and the Guardians have not activated the repurchase, the system will automatically assume that the Guardians have waived the right to be repurchased.</Typography>
                              <Typography>After activation for repurchase, all related Guardian rights automatically disappear, but CoNET tokens ($CONET) that are exchanged for referral points ($CNTP), Blast airdrop tokens, and CoNET portable hardware mining machines can be retained. CoNET tokens ($CONET) that are exchanged for super node points ($CNTP) will be destroyed. Super nodes will return to the ownership of the CoNET project. Portable hardware mining machines can continue to participate in POS mining, but the hash rate will be the same as that of standard users.</Typography>
                              <Typography>3.4 Neither party has the authority to propose changes of this repurchase agreement. Once the agreement is established and confirmed, the smart contract for the IGO sale and repurchase process will be automatically executed.</Typography>
                              <Typography>3.5 IGO is an early-stage venture for the project, and both the subscriber and repurchaser voluntarily engage in this activity. Once the contract is executed, subsequent processes will be automatically carried out by smart contract. The return on investment for all IGO users may fluctuate once the token is priced on the market.</Typography>
                              <Typography>3.6 The Participant agrees to pay the total amount in full at the time of participation.</Typography>
                              <Typography fontWeight={700}>4. RESPONSIBILITIES OF THE ISSUER</Typography>
                              <Typography>4.1 The Issuer shall use its best efforts to conduct the IGO in a fair and transparent manner.</Typography>
                              <Typography>4.2 The Issuer makes no guarantees regarding the future value of the Tokens, and the Participant acknowledges the speculative nature of investing in digital assets.</Typography>
                              <Typography fontWeight={700}>5. RISKS</Typography>
                              <Typography>5.1 The Participant acknowledges that the purchase of Guardian Plans involves risks, including but not limited to market risks, regulatory risks, and technology risks.</Typography>
                              <Typography>5.2 The Participant understands and accepts that the value of Guardian Plans may fluctuate, and there is a risk of losing the entire investment.</Typography>
                              <Typography fontWeight={700}>6. COMPLIANCE WITH LAWS</Typography>
                              <Typography>6.1 The Participant agrees to comply with all applicable laws and regulations in their jurisdiction regarding the purchase and possession of Guardian Plans.</Typography>
                              <Typography fontWeight={700}>7. DISCLAIMERS</Typography>
                              <Typography>7.1 The Issuer disclaims any warranties, express or implied, regarding the Guardian Plans, Super Node and the IGO, including but not limited to merchantability and fitness for a particular purpose.</Typography>
                              <Typography fontWeight={700}>8. MISCELLANEOUS</Typography>
                              <Typography>8.1 This Agreement constitutes the entire understanding between the parties and supersedes all prior agreements.</Typography>
                              <Typography>8.2 Amendments to this Agreement must be in writing and signed by both parties.</Typography>
                              <Typography>IN WITNESS WHEREOF, the parties hereto have executed this IGO Agreement as of the date first above written.</Typography>
                              <Typography>CoNET Foundation</Typography>
                              <Typography>Mar 20, 2024</Typography>
                            </Stack>


                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                              <Checkbox checked={isAgreementSigned} onChange={() => setIsAgreementSigned(!isAgreementSigned)} ></Checkbox>
                              <Typography style={{ fontSize: '12px', marginTop: '5px', cursor: 'pointer' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>I agree with the terms and conditions above</Typography>
                            </Box>
                          </>

                        }


                      </Box>


                      <Stack flex="1" direction="column" justifyContent="flex-end">
                        <PurchaseButtom
                          style={{
                            width: '100%',
                            textAlign: "center",
                            maxWidth: '300px',
                            margin: '0 auto',
                            marginTop: '32px',
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                            backgroundColor: circleDevider
                          }}

                          disabled={!isAgreementSigned}

                          // disabled={(!isAgreementSigned || !purchaseAble)}
                          onClick={() => { setIsBuying(!isBuying); confirmPrePurchase(); }}
                        >
                          {/* {!purchaseAble ? 'Purchase Soon' : 'Purchase'} */}
                          {!isLoading ? 'Purchase' : <CircularProgress size={24} color="inherit" />}
                        </PurchaseButtom>

                        <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                          <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                          Secure payment
                        </Stack>
                      </Stack>
                    </>
                  )}
                {(isBuying && totalPrice && !confirm) && (
                  <Box>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0px' }}>
                      <img
                        src={leftArrow}
                        alt='back'
                        height={30}
                        width={30}
                        style={{ cursor: 'pointer' }}
                        onClick={() => { setIsBuying(false); setTotalPrice(undefined) }}
                      />
                      <Typography
                        fontSize="24px"
                        color="#8DA8FF"
                        fontWeight={'700'}
                      >
                        Confirm your order
                      </Typography>
                    </div>

                    <div>
                      <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Paying with</Typography>
                      <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="16px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                        <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '20px', color: '#fff' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedCoin === 'usdt' && <img src={pay} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />}
                            {selectedCoin === 'bnb' && <img src={coin4} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px', height: '25px', width: '25px' }} />}
                            {selectedCoin === 'eth' && <img src={eth} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />}
                            <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                              {selectedCoin.toUpperCase()}
                            </Typography>
                          </div>
                        </Typography>

                      </Box>
                      <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Wallet</Typography>
                      {
                        data.wallet.length && (
                          <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                            <Box display="flex" gap="8px" alignItems="center">


                              {data.wallet[0]?.data && data.wallet[0]?.data.profileImg ? (
                                <img
                                  src={` ${data.wallet[0]?.data.profileImg}`}
                                  alt="Imagem Codificada em Base64"
                                  height="20px"
                                  width="20px"
                                  style={{ borderRadius: "100%" }}
                                />
                              ) : (
                                <SvgIcon
                                  component={ConetAvatar}
                                  inheritViewBox
                                  sx={{ fontSize: 32 }}
                                />
                              )}

                              <Box display="flex" flexDirection="column">
                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                                  {currentProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                                </Typography>
                                <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                  {currentProfile?.keyID.substring(0, 2) + currentProfile?.keyID.substring(2, 8).toUpperCase() + '...' + currentProfile?.keyID.substring(currentProfile?.keyID.length - 6, currentProfile?.keyID.length).toUpperCase()}
                                </Typography>
                              </Box>
                            </Box>

                          </Box>)
                      }
                      <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Summary</Typography>
                      <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                        <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '20px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ fontSize: '20px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{multiplierCNTP * guardianQuant * 1000}  </Typography>
                            {selectedCoin.toUpperCase()}
                          </div>
                        </Typography>

                      </Box>
                    </div>

                    <Typography style={{ color: '#fff', marginTop: '24px' }}>GAS fee: 0%</Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '164px' }}>
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                        <Checkbox checked={isAgreementSigned} onChange={() => setIsAgreementSigned(!isAgreementSigned)}></Checkbox>
                        <Typography style={{ fontSize: '16px', marginTop: '5px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>I agree the <span style={{ textDecoration: 'underline' }}>agreements</span></Typography>
                      </Box>
                      <PurchaseButtom
                        style={{
                          width: '100%',
                          textAlign: "center",
                          maxWidth: '300px',
                          margin: '0 auto',
                          marginTop: '32px',
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",
                        }}
                        disabled={!isAgreementSigned}
                        onClick={() => setConfirm(true)}
                      >
                        Pay {selectedCoin.toUpperCase()} ${multiplierCNTP * guardianQuant * 1000}
                      </PurchaseButtom>
                      <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                        <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                        Secure payment
                      </Stack>
                    </div>

                  </Box>
                )}
                {(isBuying && totalPrice && confirm) && (
                  <Box>
                    <Box>
                      <img src={img5} alt='success' width='32px' />
                      <Typography style={{ fontSize: '36px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>The transaction </Typography>
                      <p>
                        <span style={{ color: ' #8DA8FF', fontWeight: 700, fontSize: '36px' }}> was successful </span>
                      </p>
                    </Box>
                    <div>
                      <div style={{ display: 'flex', marginTop: '20px', fontSize: '24px' }}>
                        <Typography fontSize={'24px'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Fiat value </Typography>
                        <Typography fontSize={'24px'} style={{ marginLeft: '8px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}> {totalPrice} {selectedCoin.toUpperCase()}</Typography>
                      </div>
                      <Typography style={{ marginTop: '24px', marginBottom: '200px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}> Gas Fee: 0%</Typography>
                      <Box bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"} style={{ borderRadius: '16px', padding: '8px 16px', display: 'flex', gap: '24px' }}>
                        <img src={img6} width={72} />
                        <Typography fontSize="24px" fontWeight="400" lineHeight='32px' color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}><span style={{ fontWeight: 'bold' }}>Congratulations! </span>You now have <span style={{ color: ' #8DA8FF', fontWeight: 700 }}>100</span> guardian nodes.</Typography>
                      </Box>
                      <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography style={{ color: ' #8DA8FF', fontWeight: 700, cursor: 'pointer' }} onClick={() => <ProfileTest />}>
                          Go to profile
                        </Typography>
                        <PurchaseButtom
                          style={{
                            width: '100%',
                            textAlign: "center",
                            maxWidth: '300px',
                            margin: '8px auto',
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => { setIsBuying(false); setTotalPrice(undefined); setConfirm(false) }}
                        >
                          Make new one
                        </PurchaseButtom>
                        <Stack flexDirection={'row'} justifyContent={'center'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                          <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                          Secure payment
                        </Stack>
                      </div>

                    </div>

                  </Box>
                )}
              </SuperNodeBannerBuyCardBuying>
            </Box>
          </SuperNodeBannerContent >
        </Box>
      </Box>
      <SuperNodeBannerFooter style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', textAlign: 'center', flexWrap: 'wrap' }}>
        <CardNodeBannerFooter>
          <BannerIcon src={img1} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.scalability",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img2} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.global",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img3} />
          {/* <Stack direction="row" alignItems="center">
          </Stack> */}
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.maintenance",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img4} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>

            {intl.formatMessage({
              id: "platform.joinUS.miner.cloud.table.item4",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={blastImage} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            Receive official Blast Airdrops
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={cloudImage} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            Own Super Cloud Nodes
          </Typography>
        </CardNodeBannerFooter>
      </SuperNodeBannerFooter>
    </SuperNodeBannerWrapper >
  );
};