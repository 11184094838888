import { startWorker } from "@conet.project/seguro-worker-lib/build";
import store from "../../store/store";
import {
  setHasContainer,
  setIsPlatformLoading,
  setIsUnlocked,
  setLocale,
  setShowOverlay,
  setTheme,
  setWorkerServiceIsInitialized,
  setActiveProfile,
  setCurrentProfileCONET,
  setCurrentProfileCNTP,
  setDAPPOpen,
  setClientProfiles,
  setWallets,
  setPass,
} from "../../store/appState/appStateActions";
import { ContainerData } from "@conet.project/seguro-worker-lib/build/workerBridge";
import logger from "../../utilities/logger/logger";
import { Theme } from "../../theme/types";
import { Locale } from "../../localization/types";
import {
  ClientProfiles,
  ProfileData,
} from "../../store/appState/appStateReducer";
import React, { useState, useEffect } from "react";
import dark from "../../theme/dark";
let workerService: ContainerData;

// const [profileHookData, profileHook] = useState<number>(0)


// useEffect(() => {
//   console.log("HOOK -> ", profileHookData)
// }, [profileHookData])


type PasscodeFunctionParams = {
  passcode: string;
  locale: string;
  progress: (progress: any) => void;
};

type PasscodeFunctionPlataformParams = {
  passcode: string;
  locale: string;
  progress: (progress: any) => void;
  setProfileVer: React.Dispatch<React.SetStateAction<number>>,
  testPasscode: (password: string, profileVerHook: React.Dispatch<React.SetStateAction<number>>) => Promise<[boolean, string]>;
  getAllProfiles: (password: string) => Promise<any>;
  importWallet: (
    authorizationkey: string,
    privateKey: string
  ) => Promise<any[]>;
  updateProfile: (authorizationkey: string, profile: any) => Promise<any[]>;
  listSrp: string[];
};

/* initListenState("system", (data) => {
  workerService = {
    method: {},
    data: data,
    preferences: {},
    status: "UNLOCKED",
  };
}); */

type WorkerServiceResolve = "SUCCESS" | "FAILURE";

type PasscodeResolves = "SUCCESS" | "FAILURE";

export const getWorkerService = () => {
  return workerService;
};

export const setUserPreferences = () => {
  if (workerService?.preferences) {
    const { language }: Preferences = workerService.preferences;

    const theme = localStorage.getItem("mui-mode");

    if (theme == "dark") {
      // setMode(`light`);
      setTheme("Light");
    } else {
      // setMode(`dark`);
      setTheme("Dark");
    }

    if (language) {
      store.dispatch(setLocale(language));
    }
  }
};

export const initializeWorkerService = async () => {
  const [status, container] = await startWorker();

  //return store.dispatch(setWorkerServiceIsInitialized(false))

  if (status === "NOT_READY" || !container || status === "TIME_OUT") {
    store.dispatch(setWorkerServiceIsInitialized(false));
    return logger.log(
      `workerService.ts`,
      "initializeWorkerService",
      `status === 'NOT_READY' || !container`
    );
  }

  logger.log("workerService.ts", "container:", container);
  workerService = container;

  switch (true) {
    case container.status === "NOT_SET":
      store.dispatch(setHasContainer(false));
      store.dispatch(setIsUnlocked(false));
      break;
    case container.status === "LOCKED":
      store.dispatch(setHasContainer(true));
      store.dispatch(setIsUnlocked(false));
      break;
    case container.status === "UNLOCKED":
      store.dispatch(setHasContainer(true));
      store.dispatch(setIsUnlocked(true));
      break;
    default:
      break;
  }
  setUserPreferences();
  store.dispatch(setWorkerServiceIsInitialized(true));
};

export const lockPlatform = () => {
  if (workerService.method.lock) {
    workerService.method.lock().then(() => {
      store.dispatch(setShowOverlay(false));
      store.dispatch(setHasContainer(true));
      store.dispatch(setIsUnlocked(false));
    });
  }
};

export const hasPasscode = () =>
  workerService.status === "LOCKED" || workerService.status === "UNLOCKED";

export const checkIsVerified = true;

/* export const createPasscode = ({
  passcode,
  locale,
  progress,
}: PasscodeFunctionParams): Promise<PasscodeResolves> =>
  new Promise<PasscodeResolves>(async (resolve) => {
    const [status, data] = await api_createPasscode(passcode, locale); 

    // if (workerService.method.createPasscode) {
    //     const [status, data] = await workerService.method.createPasscode(passcode, (progressInteger: any) => {
    //         progress(progressInteger)
    //     })

    //     if (status === 'SUCCESS') {
    // 		if (!data) {
    // 			return resolve('FAILURE')
    // 		}
    // 		workerService = data
    //         resolve( status )
    //     }

    // 	resolve('FAILURE')

    //     logger.log('workerService.ts', 'createPasscode', status, workerService)
    // }
  });

 export const unlockPasscode = ({
  passcode,
  progress,
}: PasscodeFunctionParams): Promise<PasscodeResolves> =>
  new Promise<PasscodeResolves>(async (resolve) => {
    const [status, data] = await encrypt_TestPasscode(passcode);

    //store.dispatch(setIsPlatformLoading('unlockPasscode'))
    switch (status) {
      case "SUCCESS": {
        if (data[0]) {
          workerService = {
            method: {},
            data: data[0],
            preferences: {},
            status: "UNLOCKED",
          };
        }
        console.log(workerService);
        resolve(status);
        store.dispatch(setIsUnlocked(true));
        store.dispatch(setHasContainer(true));

        const profile = workerService.data.profiles[0];
        store.dispatch(setActiveProfile(profile));
        store.dispatch(setCurrentProfileCONET(profile.tokens.conet.balance));
        store.dispatch(setCurrentProfileCNTP(profile.tokens.cntp.balance));

        const channel = new BroadcastChannel("system");
        channel.addEventListener("message", (e) => {
          let data;
          try {
            data = JSON.parse(e.data);
          } catch (ex) {
            return console.log(
              `encrypt_TestPasscode BroadcastChannel('referrer') JSON.parse(e.data) error`,
              ex
            );
          }
          workerService = {
            method: {},
            data: data,
            preferences: {},
            status: "UNLOCKED",
          };
        });
        break;
      }
      default: {
        resolve("FAILURE");
      }
    } 
    store.dispatch(setIsPlatformLoading(null));
  });*/

export const unlockPasscodePlatform = ({
  passcode,
  setProfileVer,
  testPasscode,
  getAllProfiles,
  importWallet,
  listSrp,
  updateProfile,
  progress,
}: PasscodeFunctionPlataformParams): Promise<PasscodeResolves> =>
  new Promise<PasscodeResolves>(async (resolve) => {
    const [status, keyPasscode] = await testPasscode(passcode, setProfileVer);
    // console.log(status, keyPasscode);
    //store.dispatch(setIsPlatformLoading('unlockPasscode'))
    switch (status) {
      case true: {
        const dataAll = await getAllProfiles(keyPasscode);
        const data = dataAll.find((d: any) => d.isPrimary);

        //const res = await importWallet(keyPasscode, data.privateKeyArmor);

        if (data) {
          workerService = {
            method: {},
            data: data,
            preferences: {},
            status: "UNLOCKED",
          };
        }
        resolve(status && "SUCCESS");
        store.dispatch(setIsUnlocked(true));
        store.dispatch(setHasContainer(true));
        store.dispatch(setPass(keyPasscode));
        const profile = workerService.data;
        store.dispatch(setActiveProfile(profile));
        store.dispatch(setCurrentProfileCONET(profile.tokens?.conet?.balance));
        store.dispatch(setCurrentProfileCNTP(profile.tokens?.cntp?.balance));
        const channel = new BroadcastChannel("system");
        channel.addEventListener("message", (e) => {
          let data;
          try {
            data = JSON.parse(e.data);
          } catch (ex) {
            return console.log(
              `encrypt_TestPasscode BroadcastChannel('referrer') JSON.parse(e.data) error`,
              ex
            );
          }
          workerService = {
            method: {},
            data: data,
            preferences: {},
            status: "UNLOCKED",
          };
        });
        break;
      }
      default: {
        resolve("FAILURE");
      }
    }

    store.dispatch(setIsPlatformLoading(null));
  });

export const deletePasscode = (): Promise<PasscodeResolves> =>
  new Promise<PasscodeResolves>(async (resolve) => {
    if (workerService.method.deletePasscode) {
      const [status] = await workerService.method.deletePasscode();

      if (status === "SUCCESS") {
        return resolve("SUCCESS");
      }
      return resolve("FAILURE");
    }
  });

// export const verifyInvitation = (code: string): Promise<SeguroNetworkStatus | 'FAILURE'> => (
//     new Promise<SeguroNetworkStatus | 'FAILURE'>((resolve) => {
//         setTimeout(() => {
//             return resolve('SUCCESS')
//         }, 2000)
//         // if (workerService.SeguroNetwork.invitation) {
//         //     return resolve(workerService.SeguroNetwork.invitation(code))
//         // }
//         // return resolve('FAILURE')
//     })
// )

export type Preferences = {
  theme?: Theme;
  language?: Locale;
  extras?: {
    [key: string]: {};
  };
};

export const savePreferences = ({
  theme,
  language,
  extras,
}: Preferences): Promise<WorkerServiceResolve> =>
  new Promise<WorkerServiceResolve>((resolve) => {
    if (!workerService || !workerService.method.storePreferences) {
      throw new Error(`savePreferences Error: Empty workerService!`);
    }
    if (
      workerService &&
      workerService.preferences.preferences &&
      workerService.preferences.storePreferences
    ) {
      const updatedPreferences: Preferences = {
        ...workerService.preferences,
      };

      if (theme) {
        updatedPreferences.theme = theme;
      }

      if (language) {
        updatedPreferences.language = language;
      }

      if (extras) {
        updatedPreferences.extras = extras;
      }

      workerService.preferences = updatedPreferences;

      workerService.method.storePreferences().then(([status, container]) => {
        if (status === "SUCCESS") {
          return resolve("SUCCESS");
        }
      });
    }
    return resolve("FAILURE");
  });

export const updateProfiles = (
  clientProfiles: ClientProfiles
): Promise<WorkerServiceResolve> =>
  new Promise<WorkerServiceResolve>((resolve) => {
    if (workerService.method) {
      const kk = workerService;
    }
  });

export const createProfile = (
  profile: ProfileData
): Promise<WorkerServiceResolve> =>
  new Promise<WorkerServiceResolve>((resolve) => {
    if (workerService.method.newProfile) {
      // workerService.profiles.newProfile(profile).then(([status]) => {
      //     if (status === 'SUCCESS') {
      //         return resolve('SUCCESS')
      //     }
      //     return resolve('FAILURE')
      // })
    }
  });

export const saveProfiles = (): Promise<WorkerServiceResolve> =>
  new Promise<WorkerServiceResolve>((resolve) => {
    if (workerService.method.storeProfile) {
      workerService.method.storeProfile().then(([status]) => {
        if (status === "SUCCESS") {
          return resolve(status);
        }
        return resolve("FAILURE");
      });
    }
  });
